const CHAPTER_FRAGMENT = `
  _id
  number
  pageCount
  title
  isRead
  releaseDate
  thumbnail {
    url
  }
`;

export const historyFragment = `
  _id
  manga {
    _id
    slug
    title
    thumbnail {
      url
    }
    categories {
      _id
      level
      label
      slug
      isFavorite
    }
  }
  chapter {
  ${CHAPTER_FRAGMENT}
    next {
      ${CHAPTER_FRAGMENT}
    }
  }
  page
  lastRead
`;
