import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './indicatorPill.scss';

const IndicatorPill = ({ backgroundColor, style, className, ...props }) => {
  return (
    <span
      className={classnames('mio-indicatorPill', className)}
      {...props}
      style={{ ...style, backgroundColor: backgroundColor }}
    >
      {props.children}
    </span>
  );
};

IndicatorPill.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  style: PropTypes.object,
  children: PropTypes.element,
};

export default IndicatorPill;
