import React from 'react';
import classnames from 'classnames';
import { Text, Separator, Button } from 'design-system';
import { Responsive } from 'UI';

import './Information.scss';

function ResponsiveText(props) {
  return (
    <Responsive>
      <Text {...props} />
    </Responsive>
  );
}

function Row({ title, children, className, as = 'h3' }) {
  return (
    <div className={classnames('row', className)}>
      <ResponsiveText as={as} bold size="medium" className="title">
        {title}
      </ResponsiveText>
      <ResponsiveText size="medium" className="content">
        {children}
      </ResponsiveText>
    </div>
  );
}

function ReadMore({ children }) {
  const [readMore, setReadMore] = React.useState(false);
  return (
    <>
      {readMore && children}
      <Separator>
        <Button
          onClick={() => setReadMore(!readMore)}
          label={readMore ? 'Voir moins' : 'Voir plus'}
        ></Button>
      </Separator>
    </>
  );
}

function Information({ className, children }) {
  return (
    <div className={classnames('mio-information', className)}>{children}</div>
  );
}

export default Object.assign(Information, {
  Row,
  ReadMore,
});
