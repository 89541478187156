import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'design-system';
import { getResizedUrl, withPixelDensity } from 'utils';

import './LandingHero.scss';
import { useComputedSizes } from 'hooks';

const LandingHero = props => {
  const { tags = [], children } = props;

  const [headerRef, { width }, sizeLoaded] = useComputedSizes();

  return (
    <div id="LandingHero">
      <div className="hero-bg"></div>
      <div className="hero-container" ref={headerRef}>
        <div className="hero">
          <div className="hero-image-mobile">
            {sizeLoaded && (
              <img
                src={getResizedUrl(
                  'https://cdn.mangas.io/landing/mangas_io_landing_image_min.png',
                  {
                    width: withPixelDensity(width),
                    clamp: 100,
                  }
                )}
                width="100%"
                alt="Mangas.io"
              />
            )}
          </div>
          <div className="hero-content">
            <div className="hero-tags">
              {tags.map(tag => (
                <span key={tag} className="hero-tag">
                  {tag}
                </span>
              ))}
            </div>
            {props.customHeader && props.customHeader}
            <h1 className="hero-title" data-aos="fade-down">
              {props.title}
            </h1>
            <p className="hero-subtitle" data-aos="fade" data-aos-delay="300">
              {props.subtitle}
            </p>
            {children ? (
              children
            ) : (
              <Link to={props.ctaLink || '/signup'} className="hero-cta">
                <Button
                  size="large"
                  variant="primary"
                  label={props.cta}
                  id="cta_landing_signup"
                  data-aos="fade"
                  data-aos-delay="300"
                />
              </Link>
            )}
          </div>
          <div className="hero-image">
            <img
              src={getResizedUrl(
                'https://cdn.mangas.io/landing/landing-header-web.png',
                {
                  width: withPixelDensity(824),
                  height: withPixelDensity(603),
                }
              )}
              width="824"
              height="603"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingHero;
