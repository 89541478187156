import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Text from '../Text/Text';
import './textarea.scss';

const TextArea = ({
  className,
  placeholder,
  disabled,
  label,
  id,
  ...props
}) => {
  const [active, setActive] = useState(false);
  return (
    <div
      className={classnames(
        'mio-text-area',
        className // On garde toutes les classes passées en props
      )}
    >
      {label && (
        <label htmlFor={id}>
          <Text size="medium">{label}</Text>
        </label>
      )}

      <textarea
        id={id}
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        {...props}
      />
    </div>
  );
};

TextArea.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

TextArea.defaultProps = {};

export default TextArea;
