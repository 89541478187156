import { useEffect, useState, useRef } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const GQL_CREATE_SESSION = gql`
  mutation createCheckoutSession($planId: ID!) {
    createCheckoutSession(planId: $planId) {
      id
      error
    }
  }
`;

export default function useCheckout(delay = 1500) {
  const stripe = useRef();
  const [error, setError] = useState(null);
  const [fetchSession] = useMutation(GQL_CREATE_SESSION);

  useEffect(() => {
    async function run() {
      try {
        if (!stripe.current) stripe.current = await stripePromise;
      } catch (e) {
        console.error('loading stripe error', e);
        setError('stripe loading failed');
      }
    }
    run();
  }, []);

  async function checkout(planId) {
    const timeStart = Date.now();
    const { data } = await fetchSession({ variables: { planId } });
    const { id, error } = data.createCheckoutSession || {};

    if (error) {
      console.error('createCheckoutSession error', { error });
      return setError(error);
    }
    if (id) {
      const timeEnd = Date.now();
      const timeLeft = delay - (timeEnd - timeStart);
      const delayLeft = timeLeft > 0 ? timeLeft : 0;
      setTimeout(async () => {
        try {
          await stripe.current.redirectToCheckout({
            sessionId: id,
          });
        } catch (e) {
          console.error('[Stripe] redirection failed', e);
          setError(() => 'failed redirection');
        }
      }, delayLeft);
    }
  }

  return [checkout, error];
}
