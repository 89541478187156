import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './checkbox.scss';

const Checkbox = ({
  className,
  id,
  children,
  onChange,
  checked,
  disabled,
  ...props
}) => {
  return (
    <div className={classnames('mio-checkbox', className)}>
      <input
        className="mio-check-input"
        type="checkbox"
        checked={checked}
        id={id}
        onChange={() => onChange({ checked: !checked })}
        disabled={disabled}
      />
      <label htmlFor={id} className="mio-check-checkbox" />
      <label htmlFor={id} className="mio-check-label">
        {children}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  checked: PropTypes.bool,
};

Checkbox.defaultProps = {
  disabled: false,
  checked: false,
  onChange: () => {},
};

export default Checkbox;
