import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Text from '../Text/Text';
import Pill from '../Pill/Pill';
import PlaceholderShape from '../PlaceholderShape/PlaceholderShape';
import './mangaTile.scss';

const Example = ({
  size,
  title,
  subtitle,
  newChapter,
  picture,
  videos,
  holo,
  children,
  className,
  loading,
  ...props
}) => {
  const style = {};
  if (picture) style.backgroundImage = `url(${picture})`;
  
  return (
    <div
      className={classnames('mio-manga-tile', { [size]: true }, className)}
      {...props}
    >
      <div className="image-container">
        <div className={classnames('image', { holo })} style={style}>
          {videos?.length > 0 && (
            <video
              className="video-container"
              autoPlay
              loop
              muted="muted"
              poster={picture}
            >
              {videos.map(source => (
                <source type={source.mime} src={source.url} />
              ))}
            </video>
          )}
        </div>
        <div className="hover-effects">{children}</div>
      </div>
      {loading && (
        <>
          <PlaceholderShape
            style={{ width: '80%', height: '16px', margin: '8px 0' }}
          />
          <PlaceholderShape
            style={{ width: '60%', height: '16px', margin: '8px 0' }}
          />
        </>
      )}
      {title && (
        <Text size="medium" bold mobile={size === 'small'}>
          {title}
        </Text>
      )}

      <Text size="small" mobile={size === 'small'} className="subtitle">
        {subtitle}
      </Text>

      {newChapter && (
        <Pill
          className="has-new-chapter"
          backgroundColor="#E11F7B"
          size={size === 'large' ? 'medium' : 'small'}
        >
          Nouveau chapitre
        </Pill>
      )}
    </div>
  );
};

Example.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  title: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  newChapter: PropTypes.bool,
  holo: PropTypes.bool,
};

Example.defaultProps = {
  size: 'medium',
  newChapter: false,
};

export default Example;
