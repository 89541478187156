import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Text from '../Text/Text';
import './input.scss';

const Input = ({
  className,
  placeholder,
  helper,
  highlight,
  disabled,
  label,
  id,
  ...props
}) => {
  const [active, setActive] = useState(false);
  return (
    <div
      className={classnames(
        'mio-input',
        {
          [highlight]: true,
          disabled,
          active,
        },
        className // On garde toutes les classes passées en props
      )}
    >
      {label && (
        <label htmlFor={id}>
          <Text size="medium">{label}</Text>
        </label>
      )}

      <input
        id={id}
        type="text"
        placeholder={placeholder}
        disabled={disabled}
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        {...props}
      />

      {helper && (
        <Text size="small" className="helper">
          {helper}
        </Text>
      )}
    </div>
  );
};

Input.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  highlight: PropTypes.oneOf(['error', 'success']),
  disabled: PropTypes.bool,
  helper: PropTypes.string,
  id: PropTypes.string,
};

Input.defaultProps = {};

export default Input;
