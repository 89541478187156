import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiLock } from '@mdi/js';
import classnames from 'classnames';
import './chapterCard.scss';
import Text from '../Text/Text';

const ChapterCard = ({
  title,
  number,
  subtitle,
  badge,
  alert,
  faded,
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={classnames('mio-chapter-card', { faded }, className)}
      {...props}
    >
      <div className="number-container">
        {alert && <span className="bullet" />}
        {number ? number.toString().padStart(2, '0') : '#'}
      </div>

      <div className="title-container">
        <Text className="textEllipsis" size="medium" mobile={true}>
          {title}
        </Text>
        <Text size="medium" mobile={true} style={{ opacity: '0.7' }}>
          {subtitle}
        </Text>
      </div>

      {badge && (
        <div className="badge-container">
          {badge === 'signup' && (
            <div className="access-icon signup">M'ABONNER</div>
          )}
          {badge === 'login' && (
            <div className="access-icon login">ME CONNECTER</div>
          )}
          {badge === 'locked' && (
            <div className="access-icon locked">
              <Icon path={mdiLock} />
            </div>
          )}
          {badge === 'read' && <div className="access-icon read">LIRE</div>}
        </div>
      )}
    </div>
  );
};

ChapterCard.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subtitle: PropTypes.string,
  badge: PropTypes.oneOf(['signup', 'login', 'locked', 'read']),
  alert: PropTypes.bool,
  faded: PropTypes.bool,
};

ChapterCard.defaultProps = {
  alert: false,
  faded: false,
};

export default ChapterCard;
