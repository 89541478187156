import React from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Text } from 'design-system';

import {
  useAuth,
  useAppState,
  voucherCodeSelector,
  passCultureCodeSelector,
} from 'hooks';

import { Signup } from 'Components';
import { FormFooter, OnboardingBackground } from 'UI';

function mapStoreToState(state) {
  return {
    voucher: voucherCodeSelector(state),
    passCulture: passCultureCodeSelector(state),
  };
}

export default function SignupPage() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const { isAuthenticated } = useAuth();
  const [{ voucher, passCulture }] = useAppState(mapStoreToState);

  if (params.has('invitation'))
    return (
      <Redirect to={`/carte-cadeau/activation/${params.get('invitation')}`} />
    );
  if (params.has('pass'))
    return <Redirect to={`/pass-culture/${params.get('pass')}`} />;
  if (isAuthenticated) {
    if (voucher || passCulture) {
      return <Redirect to="/" />;
    }
    return <Redirect to="/abonnement" />;
  }

  return (
    <OnboardingBackground id="mio-signupPage">
      <Signup />
      <FormFooter>
        <Text className="text-grey" size="small" mobile>
          Tu as déjà un compte&nbsp;?{' '}
          <Link className="text-link" to="/login">
            Connecte-toi
          </Link>
        </Text>
      </FormFooter>
    </OnboardingBackground>
  );
}
