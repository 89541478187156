import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Icon from '@mdi/react';
import {
  mdiArrowLeft,
  mdiWalletGiftcard,
  mdiKey,
  mdiChevronRight,
} from '@mdi/js';
import { Link } from 'react-router-dom';
import { Text, Button } from 'design-system';

import { FloatingMenu } from 'UI';
import { useProfile } from 'hooks';
import { track } from 'utils';

import './VoucherSlide.scss';

export default function VoucherSlide(props) {
  const { className, navigation } = props;
  const [disabled, setDisabled] = useState(true);
  const { profile, loading } = useProfile({ subscription: true });

  function handleVoucher() {
    if (!disabled) navigation.push('voucher_code');
  }

  useEffect(() => {
    // TODO : Meilleure gestion du statut actuel (extended, etc)
    if (profile && profile.info) {
      if (
        ['visitor', 'invited', 'voucher', 'canceled'].includes(
          profile.info.status
        )
      )
        setDisabled(false);
      else setDisabled(true);
    }
  }, [loading, profile]);

  return (
    <div className={classnames(className, 'slide', 'voucher-slide')}>
      <FloatingMenu.Header
        className="clickable"
        onClick={() => navigation.pop()}
      >
        <span className="back-icon">
          <Icon path={mdiArrowLeft} />
        </span>
        Carte cadeau
      </FloatingMenu.Header>
      <FloatingMenu.Body>
        <Link className="row clickable" to="/carte-cadeau">
          <span className="settings-icon">
            <Icon path={mdiWalletGiftcard} />
          </span>
          <Text>Acheter une carte cadeau</Text>
        </Link>
        <div
          className={classnames('row clickable', { disabled })}
          onClick={handleVoucher}
        >
          <span className="settings-icon">
            <Icon path={mdiKey} />
          </span>
          <Text className="label">Activer ma carte cadeau</Text>
          <span className="settings-icon">
            <Icon path={mdiChevronRight} />
          </span>
        </div>
        {!loading && disabled && (
          <div className="info">
            <Text size="small">
              Tu as déjà un abonnement Mangas.io en cours. Si tu souhaites
              prolonger ton abonnement à l’aide d’une carte cadeau,
              contacte-nous par email en indiquant ton code.
            </Text>
            <Button
              variant="primary"
              label="Contacter l'équipe"
              onClick={async () => {
                track('Contact Clicked', {
                  url: 'mailto:hello@mangas.io',
                  type: 'contact',
                });

                window.open('mailto:hello@mangas.io');
              }}
              fullWidth
            />
          </div>
        )}
      </FloatingMenu.Body>
    </div>
  );
}
