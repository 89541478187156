import React, { useEffect } from 'react';
import AOS from 'aos';
import Rellax from '../../assets/js/rellax/rellax.js';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Helmet } from 'react-helmet';
import 'aos/dist/aos.css';
import { Button, Text } from 'design-system';
import { Link } from 'react-router-dom';
import {
  LandingFaq,
  LandingFeatures,
  LandingPartners,
  LandingHero,
  TitleHeader,
  LandingSupportCoffee,
  FlashMessage,
  PublishersBloc,
  TrendingLine,
} from 'Components';
import { ResponsiveImage, ResponsiveText } from 'UI';
import { trackHandler } from 'utils';

import './PassCultureLanding.scss';

export default function PassCultureLanding({ noindex = false }) {
  useEffect(() => {
    AOS.init({
      once: true,
      anchorPlacement: 'top-center',
    });

    new Rellax('.rellax', {
      breakpoints: [576, 1468, 2501],
    });

    document.querySelector('html').classList.add('smooth');

    return () => {
      document.querySelector('html').classList.remove('smooth');
    };

    /*eslint-disable-next-line*/
  }, []);

  return (
    <>
      <LandingHero
        customHeader={
          <div className="headerPC">
            <img
              src={'https://cdn.mangas.io/landing/pass-culture-white.png'}
              width="150"
              alt={'Pass Culture Logo'}
            />

            <img
              src={'https://cdn.mangas.io/landing/mio-logo-white.png'}
              width="150"
              alt={'Mangas io Logo'}
            />
          </div>
        }
        title="Tu as entre 15 et 18 ans ?"
        subtitle="Accède à des milliers de mangas en illimité gratuitement et profite de tout le contenu Mangas.io grâce à ton Pass Culture !"
      >
        <div className="headerButtons">
          <a
            href="https://passculture.app/recherche/resultats?query=%22mangas.io%22"
            onClick={e => trackHandler(e, 'Pass Culture Clicked')}
          >
            <Button
              size="large"
              variant="primary"
              label="Activer mon Pass Culture"
              id="cta_landing_access"
              data-aos="fade-up"
              data-aos-delay="300"
            />
          </a>
          <Link to="/pass-culture/activation">
            <Button
              size="small"
              variant="text"
              label="J'ai un code Pass Culture"
              id="cta_landing_access"
              data-aos="fade-up"
              data-aos-delay="300"
            />
          </Link>
        </div>

        <ResponsiveText
          className="btm-header-text"
          size="large"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          Rejoins plus de 100k de passionné.e.s !
        </ResponsiveText>
        <div className="stores" data-aos="fade-up" data-aos-delay="300">
          <div className="storeButtons">
            <ResponsiveText size="small">
              Application disponible sur :
            </ResponsiveText>
            <div>
              <img
                src={'https://cdn.mangas.io/landing/logo-apple.png'}
                width="30"
                alt={'App Store'}
              />
              <img
                src={'https://cdn.mangas.io/landing/logo-google-play.png'}
                width="30"
                alt={'Google Store'}
              />
            </div>
          </div>
          <img
            src={'https://cdn.mangas.io/landing/rating-stars-4-5-min.png'}
            width="100"
            alt={'Stars Store'}
          />
        </div>
      </LandingHero>

      <div id="LandingPage">
        {noindex && (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        )}
        <Helmet
          script={[
            helmetJsonLdProp({
              '@context': 'http://schema.org',
              '@type': 'FAQPage',
              mainEntity: [
                {
                  '@type': 'Question',
                  name: 'Comment lire ses mangas en ligne ?',
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text:
                      "Il existe plusieurs solutions légales pour lire tes mangas en ligne ! Il est possible d'acheter des mangas numériques à l'unité mais cette solution devient rapidement coûteuse... C'est pourquoi nous avons lancé Mangas.io et son modèle à l'abonnement : pour le prix d'un manga par mois, nous te proposons des milliers de chapitres à lire en illimité !",
                  },
                },
                {
                  '@type': 'Question',
                  name: 'Pourquoi lire ses mangas légalement ?',
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text:
                      'Tout simplement pour soutenir les artistes qui te font rêver au quotidien ! Pour chacune de tes lectures, nous rémunérons tes mangakas préférés ! Notre plateforme s’engage à mettre en place un modèle de rémunération juste, viable et efficace pour les auteur.ice.s et les éditeurs. Il est temps de réconcilier les intérêts des lecteur.ice.s et des créateur.ice.s !',
                  },
                },
              ],
            }),
          ]}
        />
        <Helmet
          script={[
            helmetJsonLdProp({
              '@context': 'http://schema.org',
              '@type': 'Organization',
              name: 'Mangas.io',
              url: 'https://www.mangas.io/',
              logo: 'https://www.mangas.io/favicon_180.png',
              slogan: 'Tes mangas en ligne légalement et sans pub !',
              description:
                "Mangas.io est une plateforme légale de lecture de mangas en ligne. Pour le prix d'un manga par mois, tu accèdes à des milliers de chapitres à lire en illimité et sans pub.",
            }),
          ]}
        />

        <FlashMessage
          location="landing"
          render={children => <section id="flash-message">{children}</section>}
        />
        <div data-aos="fade-up" data-aos-delay="300">
          <PublishersBloc />
        </div>

        <div className="popularConnected-line">
          <TitleHeader
            title="Des milliers de mangas sans débourser un seul centime"
            subtitle="Voici nos mangas les plus populaires"
          />
          <TrendingLine />
          <Link className="buttonLink" to={`/manga-en-ligne`}>
            <Button
              id="button"
              size="large"
              variant="primary"
              label="Voir notre catalogue complet"
            />
          </Link>
        </div>

        <TitleHeader
          title="L’app ultime de lecture pour les fans de mangas !"
          subtitle="Découvre nos meilleurs titres gratuitement 👇"
        />
        <LandingFeatures />

        <TitleHeader
          title="Bénéficie de ton abonnement Mangas.io gratuitement"
          subtitle="Pour cela, sers toi de ton Pass Culture !"
        />
        <div className="pc-steps">
          <div className="step-card">
            <Text>Je me connecte sur Pass culture</Text>
            <a
              href="https://passculture.app/recherche/resultats?query=%22mangas.io%22"
              onClick={e => trackHandler(e, 'Pass Culture Clicked')}
            >
              <Button
                size="large"
                variant="primary"
                label="Choisir mon offre"
                id="cta_landing_access"
              />
            </a>
          </div>
          <div className="step-card">
            <Text>Je choisis l’offre Mangas.io qui m’interesse </Text>
            <ResponsiveImage
              width="50"
              src="https://cdn.mangas.io/images/coffee/reading.png"
            />
          </div>
          <div className="step-card">
            <Text>
              Je crée mon compte sur Mangas.io et j'entre mon code Pass Culture
              ici 👇
            </Text>
            <Link to="/pass-culture/activation">
              <Button
                size="large"
                variant="primary"
                label="Activer mon Pass Culture"
                id="cta_landing_access"
              />
            </Link>
          </div>
        </div>

        <LandingFaq
          displayContact={false}
          title="Questions fréquentes"
          page_name="faq_landing"
        />

        <LandingSupportCoffee
          customCTA={
            <a
              href="https://passculture.app/recherche/resultats?query=%22mangas.io%22"
              onClick={e => trackHandler(e, 'Pass Culture Clicked')}
            >
              <Button
                size="large"
                variant="gold"
                label="Choisir mon offre"
                id="cta_landing_access"
                data-aos="fade-up"
                data-aos-delay="300"
              />
            </a>
          }
        />
        <LandingPartners />
      </div>
    </>
  );
}
