import React from 'react';
import Icon from '@mdi/react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Disclaimer, Text } from 'design-system';
import {
  mdiArrowLeft,
  mdiArrowRight,
  mdiClose,
  mdiCrownOutline,
} from '@mdi/js';
import useEventListener from 'hooks/useEventListener';
import { useSwipeable } from 'react-swipeable';
import classnames from 'classnames';

import { SimilarMangasLine } from 'Components';
import { dayjs } from 'utils';
import { Button } from 'UI';
import { useReaderSettings, useProfile, useAuth, useContext } from 'hooks';

import './ChapterInsert.scss';

export default function ChapterDivider(props) {
  const {
    className,
    disclaimer,
    action,
    onClickRight,
    onClickLeft,
    backUrl,
    id,
    subtitle,
    withKeyListener,
    direction,
    reader,
    chapterContext = 'current',
  } = props;

  useEventListener('keyup', e => {
    if (withKeyListener) {
      if (e.key === 'ArrowRight') onClickRight();
      else if (e.key === 'ArrowLeft') onClickLeft();
    }
  });
  const history = useHistory();
  const handlers = useSwipeable({
    onSwipedLeft: onClickRight,
    onSwipedRight: onClickLeft,
  });
  const { value: manga } = useContext('MangaContext');
  const chapter =
    chapterContext === 'next' ? manga.chapter.next : manga.chapter;
  const releaseDate = chapter?.releaseDate;
  const copyright = manga.chapter.copyright;

  const actions = {
    finished: {
      title: 'Fin du dernier chapitre',
      label: 'Fermer le lecteur',
      icon: mdiClose,
    },
    previous: {
      title: 'Début du chapitre',
      label: 'Chapitre précédent',
      icon: mdiArrowRight,
    },
    next: {
      title: `Fin du chapitre`,
      label: 'Chapitre suivant',
      icon: mdiArrowLeft,
    },
    unreleased: {
      title: 'Prochain chapitre le ',
      label: 'Fermer le lecteur',
      icon: mdiClose,
    },
    open: {
      title: "Fin de l'extrait gratuit",
      label: 'Créer un compte',
      subtitle:
        'Pour découvrir la suite, crée dès maintenant un compte sur Mangas.io !',
      primary: true,
      //variant instead of bool
    },
    preview: {
      title: (
        <>
          {manga.title}&nbsp;-&nbsp;{chapter.title}
        </>
      ),
      subtitle:
        'L’intégralité de ce manga est inclus dans l’abonnement illimité au prix d’un tome par mois !',
      label: 'Activer mon essai gratuit',
      gold: true,
      icon: mdiCrownOutline,
      //variant instead of bool
    },
    signup: {
      title: (
        <>
          {manga.title}&nbsp;-&nbsp;{chapter.title}
        </>
      ),
      subtitle:
        'Pour profiter de la plateforme et découvrir nos extraits gratuits, il te suffit simplement de créer un compte sur Mangas.io !',
      label: 'Créer un compte',
      primary: true,
    },
    subscribe: {
      title: (
        <>
          {manga.title}&nbsp;-&nbsp;{chapter.title}
        </>
      ),
      subtitle:
        'L’intégralité de ce manga est inclus dans l’abonnement illimité au prix d’un tome par mois !',
      label: 'Activer mon essai gratuit',
      gold: true,
      icon: mdiCrownOutline,
      //variant instead of bool
    },
    bundle: {
      title: (
        <>
          {manga.title}&nbsp;-&nbsp;{chapter.title}
        </>
      ),
      subtitle:
        "Ce chapitre n'est pas disponible avec ton offre actuelle. Si tu souhaites continuer, abonne-toi pour lire en illimité au prix d'un tome par mois !",
      label: 'Activer mon essai gratuit',
      gold: true,
      icon: mdiCrownOutline,
    },
  };
  const {
    title,
    label,
    icon,
    subtitle: defaultSubtitle,
    primary,
    gold,
    secondary,
    state,
  } = actions[action];
  const blockSubtitle = subtitle || defaultSubtitle;

  return (
    <div
      className={classnames('chapter-insert', className)}
      id={id}
      {...handlers}
    >
      <div className="chapter-insert-content">
        <h1 className="--title-s">
          {title}{' '}
          {action === 'unreleased' && releaseDate
            ? dayjs(releaseDate).format('DD/MM/YYYY')
            : ''}
        </h1>
        {['previous', 'next', 'finished'].includes(action) && (
          <p className="--subtitle">{chapter.title}</p>
        )}
        {blockSubtitle && <p className="--subtitle">{blockSubtitle}</p>}
        {(onClickLeft || onClickRight || backUrl) && (
          <>
            <Button
              secondary={secondary || (!primary && !gold)}
              primary={primary}
              gold={gold}
              label={label}
              className={classnames(`direction-${direction}`, `mode-${reader}`)}
              icon={icon && <Icon path={icon} />}
              onClick={() => {
                if (action === 'previous') onClickRight();
                else if (action === 'next') onClickLeft();
                else history.push(backUrl, state);
              }}
            />
            {disclaimer && (
              <Disclaimer
                className="disclaimer-info"
                type="warning"
                title={disclaimer}
              />
            )}
          </>
        )}
        {!['next', 'previous', 'finished', 'unreleased'].includes(action) && (
          <Link to={`/lire/${manga.slug}`} className="button-close">
            <Button secondary={true} label="Fermer le lecteur" />
          </Link>
        )}

        {['subscribe', 'signup'].includes(action) && (
          <Link to={'/login'}>
            <Text size="small" className="button-login">
              Tu as déjà un compte ? Connecte-toi
            </Text>
          </Link>
        )}

        {copyright &&
          ['next', 'previous', 'finished', 'unreleased'].includes(action) && (
            <div className="chapter-copyright">
              <Text className="copyright">{copyright}</Text>
            </div>
          )}
      </div>
      {['bundle', 'finished', 'preview', 'unreleased'].includes(action) && (
        <div className="similarMangasContainer">
          <SimilarMangasLine withTitle manga={manga} />
        </div>
      )}
    </div>
  );
}

export function SignupInsert(props) {
  return <ChapterDivider id="signup-insert" action="signup" {...props} />;
}

export function PayingAccessInsert(props) {
  return (
    <ChapterDivider id="paying-access-insert" action="subscribe" {...props} />
  );
}

export function NotInBundlesInsert(props) {
  return (
    <ChapterDivider
      id="paying-access-insert"
      action="bundle"
      disclaimer="Activer ta période d'essai mettra fin à ton offre actuelle"
      {...props}
    />
  );
}

export function ChapterNotPreviewInsert(props) {
  return (
    <ChapterDivider id="chapter-next-divider" action="preview" {...props} />
  );
}

export function ChapterNotOpenInsert(props) {
  return <ChapterDivider id="chapter-next-divider" action="open" {...props} />;
}

export function ChapterNotReleasedInsert(props) {
  return (
    <ChapterDivider id="chapter-next-divider" action="unreleased" {...props} />
  );
}

export function ChapterNextDivider(props) {
  const { slug, navigateTo, currentPage, ...dividerProps } = props;
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const { profile } = useProfile();
  const today = dayjs();
  const { value: manga } = useContext('MangaContext');
  const { chapter } = manga;

  if (!chapter.next)
    return (
      <ChapterDivider
        id="chapter-next-divider"
        action="finished"
        chapterContext="current"
        backUrl={`/lire/${slug}/`}
        onClickLeft={() => {
          history.push(`/lire/${slug}/`);
        }}
        onClickRight={() => {
          history.push(currentPage - 1);
        }}
        {...dividerProps}
      />
    );

  if (dayjs(chapter.next.releaseDate).isAfter(today))
    return (
      <ChapterNotReleasedInsert
        chapterContext="next"
        backUrl={`/lire/${slug}/`}
      />
    );

  if (!isAuthenticated && chapter.next.access === 'FREE')
    return <ChapterNotOpenInsert chapter={chapter} backUrl="/signup" />;
  if (!profile.isActive && chapter.next.access === 'PAID') {
    return <PayingAccessInsert backUrl={`/abonnement`} chapterContext="next" />;
  }

  return (
    <ChapterDivider
      id="chapter-next-divider"
      action="next"
      chapterContext="next"
      onClickLeft={() => {
        navigateTo(0);
        history.push(`/lire/${slug}/${chapter.next.number}/1`);
      }}
      onClickRight={() => navigateTo(currentPage - 1)}
      {...dividerProps}
    />
  );
}

export function ChapterPreviousDivider(props) {
  const { slug, navigateTo, ...dividerProps } = props;
  const history = useHistory();
  const { isAuthenticated } = useAuth();
  const { profile } = useProfile();
  const { value: manga } = useContext('MangaContext');
  const { chapter } = manga;
  if (
    !isAuthenticated &&
    chapter.previous &&
    chapter.previous.access === 'FREE'
  )
    return <ChapterNotOpenInsert chapterContext="current" backUrl="/signup" />;
  if (
    !profile.isActive &&
    chapter.previous &&
    chapter.previous.access === 'PAID'
  )
    return (
      <ChapterNotPreviewInsert
        chapterContext="current"
        backUrl={`/abonnement`}
      />
    );
  if (!chapter.previous) return null;
  return (
    <ChapterDivider
      id="chapter-previous-divider"
      action="previous"
      chapterContext="current"
      onClickRight={() => {
        navigateTo(chapter.previous.pageCount - 1);
        history.push(
          `/lire/${slug}/${chapter.previous.number}/${chapter.previous.pageCount}`
        );
      }}
      onClickLeft={() => navigateTo(1)}
      {...dividerProps}
    />
  );
}

export function ChapterBeginDivider(props) {
  const [{ reader }] = useReaderSettings();
  return ['vertical', 'webtoon'].includes(reader) ||
    props.direction === 'ltr' ? (
    <ChapterPreviousDivider {...props} reader={reader} />
  ) : (
    <ChapterNextDivider {...props} reader={reader} />
  );
}

export function ChapterEndDivider(props) {
  const [{ reader }] = useReaderSettings();
  return ['vertical', 'webtoon'].includes(reader) ||
    props.direction === 'ltr' ? (
    <ChapterNextDivider {...props} reader={reader} />
  ) : (
    <ChapterPreviousDivider {...props} reader={reader} />
  );
}
