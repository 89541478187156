import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Pill } from 'design-system';
import classnames from 'classnames';
import { useSinglePrismicDocument, PrismicText } from '@prismicio/react';

import { ResponsiveText } from 'UI';

import './LandingPopularManga.scss';

const MangaTilesSection = props => {
  const { mangas = [], loading, children } = props;
  return (
    <div className="content-wrapper">
      {children}
      {loading && <article className="pop-card MangaInfo first" />}

      {mangas.map((manga, index) => (
        <article
          key={index}
          className={classnames('pop-card  MangaInfo', {
            'left-cover': manga.align_right,
          })}
        >
          <div
            className="cover"
            style={{
              backgroundImage: `url('${manga.image_desktop.url}')`,
            }}
          ></div>
          <div
            className="cover-mobile"
            style={{
              backgroundImage: `url('${manga.image_mobile.url}')`,
            }}
          ></div>
          <div className="--title">
            <img src={manga.logo.url} width="283" alt={manga.title} />
          </div>
          <p className="--text">
            <PrismicText field={manga.description} />
          </p>
          {!props.hideCTA && (
            <Link to={manga.url}>
              <Button
                size="large"
                variant="primary"
                label="Lire le 1er chapitre gratuitement"
              />
            </Link>
          )}
        </article>
      ))}
    </div>
  );
};

const LandingPopularManga = props => {
  const [document, { state }] = useSinglePrismicDocument('popularmangas');

  const loading = state !== 'loaded';
  const popularMangas = !loading ? document.data.popular : [];

  return (
    <div id="LandingPopularManga">
      <div className="section-container">
        <MangaTilesSection
          loading={loading}
          mangas={popularMangas.filter(manga => manga.zone === 'prepub')}
        >
          <div className="pills">
            <Pill backgroundColor="#06972e">Nouveauté</Pill>
            <Pill
              style={{
                color: 'black',
                background: 'linear-gradient(90deg, #F1E96A 0%, #FFFF01 100%)',
              }}
            >
              Prépublication gratuite
            </Pill>
          </div>
          <ResponsiveText as="h2" type="display" size="medium" bold>
            Avant-première gratuite
          </ResponsiveText>
          <ResponsiveText type="display" size="small" className="secondary">
            À découvrir exclusivement en prépublication chaque semaine sur
            Mangas.io
          </ResponsiveText>
        </MangaTilesSection>

        <MangaTilesSection
          loading={loading}
          mangas={popularMangas.filter(manga => manga.zone === 'simultrad')}
        >
          <div className="pills">
            <Pill id="simultradPill">Simultrad</Pill>
          </div>
          <ResponsiveText as="h2" type="display" size="medium" bold>
            En direct de l'Asie
          </ResponsiveText>
          <ResponsiveText type="display" size="small" className="secondary">
            Les derniers chapitres de tes mangas préférés en simultrad
            exclusivement sur Mangas.io
          </ResponsiveText>
        </MangaTilesSection>

        <MangaTilesSection
          loading={loading}
          mangas={popularMangas.filter(manga => manga.zone === 'popular')}
        >
          <ResponsiveText as="h2" type="display" size="medium" bold>
            Mangas populaires
          </ResponsiveText>

          <ResponsiveText type="display" size="small" className="secondary">
            Découvre nos meilleurs titres gratuitement
            <span role="img" aria-label="En dessous">
              👇
            </span>
          </ResponsiveText>
        </MangaTilesSection>
      </div>
    </div>
  );
};

export default LandingPopularManga;
