import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './cornerTag.scss';

/**
 * CornerTag for indicating an item classment
 */
const CornerTag = ({ variant, ...props }) => {
  return (
    <span
      className={classnames('mio-cornertag', { [variant]: true })}
      {...props}
    >
      {props.children}
    </span>
  );
};

CornerTag.propTypes = {
  //my component color variant
  variant: PropTypes.oneOf(['gold', 'silver', 'bronze', 'primary']),
  children: PropTypes.element,
};

export default CornerTag;
