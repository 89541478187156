import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Button } from 'design-system';
import { useQuery } from 'hooks';
import { dayjs } from 'utils';
import { mdiOpenInNew, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { mdiBookOpenPageVariant } from '@mdi/js';
import './ButtonAffiliation.scss';

const GQL_GET_AFFILIATIONS = gql`
  query getAffiliations($mangaId: ID) {
    affiliations(mangaId: $mangaId) {
      _id
      provider
      url
      price
      volume {
        thumbnail {
          url
        }
        number
        chapterStart
        chapterEnd
        publicationDate
        releaseDate
      }
    }
  }
`;

function ButtonAffiliation(props) {
  const { manga, tomevolume } = props;
  const { data, loading } = useQuery(GQL_GET_AFFILIATIONS, {
    variables: {
      mangaId: manga._id,
    },
  });
  const [displayAffiliationLinks, setAffiliationLinks] = useState(false);
  const toggleAffiliationLinks = () =>
    setAffiliationLinks(!displayAffiliationLinks);

  if (loading) return null;
  const { affiliations } = data;
  const sortedAffiliations = affiliations
    .filter(affiliation => !!affiliation.volume)
    .sort((a, b) => a.volume.number - b.volume.number);
  const currentVolume = sortedAffiliations.map(el => el);
  const currentTome = currentVolume.find(
    tome => tome.volume.number === tomevolume
  );

  return (
    <div>
      <Button
        id="btn-affiliation"
        size="large"
        icon={<Icon path={mdiBookOpenPageVariant} />}
        variant="secondary"
        label="Acheter en version papier"
        onClick={toggleAffiliationLinks}
      />

      {displayAffiliationLinks && (
        <div className="affiliation-modal">
          <Icon
            className="icon-close"
            path={mdiClose}
            onClick={() => setAffiliationLinks(null)}
          />
          <div className="row" style={{ textAlign: 'center' }}>
            <img
              alt="Volume"
              className="img-affiliation"
              src={currentTome.volume.thumbnail.url}
            />
          </div>
          <div className="bottom">
            <div className="row">
              <div className="info">
                <p className="--subtitle">Série</p>
                <p className="--text">{manga.title}</p>
              </div>
              <div className="info">
                <p className="--subtitle">Volume</p>
                <p className="--text">{currentTome.volume.number}</p>
              </div>
            </div>
            <div className="row">
              <div className="info">
                <p className="--subtitle">Chapitres</p>
                <p className="--text">
                  {currentTome.volume.chapterStart}-
                  {currentTome.volume.chapterEnd}
                </p>
              </div>
              <div className="info">
                <p className="--subtitle">Année de parution</p>
                <p className="--text">
                  {currentTome.volume.publicationDate
                    ? dayjs(currentTome.volume.publicationDate).format('YYYY')
                    : '-'}
                </p>
              </div>
            </div>
            <div className="row row-subtitles">
              <p className="--subtitle">REVENDEUR</p>
              <p className="--subtitle">PRIX</p>
            </div>
            <div className="row row-links">
              <div className="info">
                <div className="links">
                  <p className="--text">
                    <a
                      className="link"
                      href={currentTome.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {currentTome.provider} <Icon path={mdiOpenInNew} />
                    </a>
                  </p>
                </div>
              </div>
              <div className="info">
                <p className="--text">{currentTome.price.toFixed(2)}€</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {!displayAffiliationLinks && <div style={{ display: 'none' }}></div>}
    </div>
  );
}
export default ButtonAffiliation;
