import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Switch, MemoryRouter } from 'react-router-dom';

import { Input, Button, Text } from 'design-system';

import {
  CaptiveNavbar,
  LandingFaq,
  LandingFeatures,
  LandingHero,
  PublishersBloc,
  LandingPopularManga,
  LandingUsersFeedback,
  MinimalFooter,
  Success,
  DisclaimerInvited,
} from 'Components';

import { metrics, PublicRoute, trackHandler } from 'utils';
import {
  useAuth,
  useAppState,
  useProfile,
  BrowserHistoryProvider,
  useBrowserHistory,
} from 'hooks';

import { OnboardingBackground } from 'UI';

import '../Landing/LandingPage.scss';
import './RedeemPage.scss';

const errors = {
  'Invalid code': 'Code invalide',
  'Code limit reached': 'Code déjà utilisé',
  INVALID_STATUS_SEQUENCE: "Impossible d'activer ce code sur votre compte",
  default: 'Une erreur est survenue',
};

function getErrorMessage(error) {
  if (errors[error]) return errors[error];
  return errors.default;
}

function RedeemPageLibrary({ code = '' }) {
  const { isAuthenticated, validateInvite, addInvite } = useAuth();
  const [currentCode, setCode] = useState(code);
  const [disabled, setDisabled] = useState(currentCode.length === 0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [, actions] = useAppState();
  const history = useHistory();
  const browserHistory = useBrowserHistory();
  const [isPayingUser, setIsPayingUser] = useState(false);
  const { profile, loading: profileLoading, refreshProfile } = useProfile({
    subscription: true,
  });

  useEffect(() => {
    if (profile && profile.info) {
      if (!['visitor', 'invited', 'voucher'].includes(profile.info.status)) {
        setIsPayingUser(true);
        setDisabled(true);
      }
    }
  }, [profileLoading, profile]);

  useEffect(() => {
    setDisabled(!isPayingUser && currentCode.length === 0);
  }, [currentCode, isPayingUser]);

  const handleSubmit = async () => {
    setLoading(true);
    if (!isAuthenticated) {
      const { error, valid, code } = await validateInvite(currentCode);
      setLoading(false);
      if (error) setError(getErrorMessage(error));
      if (valid) {
        actions.setVoucherAction(currentCode);
        browserHistory.push('/signup');
      } else setError(getErrorMessage(code));
    } else {
      const { error, valid, code } = await addInvite(currentCode);
      setLoading(false);
      if (error) setError(getErrorMessage(error));
      if (valid) {
        actions.setVoucherAction(null);
        await refreshProfile();
        history.push('/success');
      } else setError(getErrorMessage(code));
    }
  };

  if (profileLoading) return null;

  return (
    <div id="Landing" className="RedeemPage">
      <CaptiveNavbar
        cta={
          <Button
            variant="primary"
            label="Activer l’offre"
            loading={loading}
            disabled={disabled}
            onClick={!disabled ? handleSubmit : undefined}
          />
        }
      />
      <header>
        <LandingHero
          tags={[
            'Sans engagement',
            'Pas de publicité',
            `${metrics.readers} utilisateur.ice.s`,
          ]}
          title="Carte Bibliothèque Mangas.io"
          subtitle={
            isPayingUser ? (
              <>
                Tu es déjà abonné.e à Mangas.io. Si tu souhaites prolonger ton
                abonnement à l’aide d’une carte cadeau,{' '}
                <a
                  href="mailto:contact@mangas.io"
                  onClick={e =>
                    trackHandler(e, 'Contact Clicked', { type: 'contact' })
                  }
                >
                  contacte-nous par email
                </a>{' '}
                en indiquant ton code.
              </>
            ) : (
              `Récupère ta carte Mangas.io dans ta bibliothèque, rentre ici ton code secret et accède pendant un mois à des dizaines de milliers de chapitres à lire en illimité sur Mangas.io !`
            )
          }
        >
          <div className="code-validation-input">
            <Input
              value={currentCode}
              onChange={e => setCode(e.target.value)}
              placeholder="Ta carte Mangas.io"
              highlight={error ? 'error' : null}
              helper={error}
            />
            <Button
              label="Activer"
              variant="primary"
              size="large"
              loading={loading}
              disabled={disabled}
              onClick={!disabled ? handleSubmit : undefined}
            />
          </div>
          <DisclaimerInvited profile={profile} />
        </LandingHero>
      </header>

      <section id="advantage" className="section">
        <PublishersBloc />
      </section>

      <section id="faq" className="section">
        <LandingFaq title="Tout savoir sur Mangas.io" page_name="faq_landing" />
      </section>

      <section id="popularManga" className="section">
        <LandingPopularManga hideCTA />
      </section>

      <section id="features" className="section">
        <LandingFeatures />
      </section>

      <section id="feedback" className="section">
        <LandingUsersFeedback />
      </section>

      <MinimalFooter />
    </div>
  );
}

function RedeemSuccess({ onClick }) {
  const history = useBrowserHistory();
  // TODO : event tracking ?
  return (
    <OnboardingBackground id="mio-loginPage">
      <Success>
        <Text bold>Félicitations !</Text>
        <Text>
          Ta carte cadeau a bien été activée. Bonne lecture sur Mangas.io !
        </Text>
        <Button
          fullWidth
          className="divider"
          variant="primary"
          label="Continuer"
          onClick={() => history.push('/')}
        />
      </Success>
    </OnboardingBackground>
  );
}

export default function RedeemPageLibraryRouter() {
  const { code } = useParams();
  return (
    <BrowserHistoryProvider>
      <MemoryRouter>
        <Switch>
          <PublicRoute path="/success" component={<RedeemSuccess />} />
          <PublicRoute path="/" component={<RedeemPageLibrary code={code} />} />
        </Switch>
      </MemoryRouter>
    </BrowserHistoryProvider>
  );
}
