import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { VolumeCard, Separator, Button, Pill } from 'design-system';

import { useResponsive } from 'hooks';
import { dayjs, getResizedUrl, withPixelDensity } from 'utils';
import { ChapterList } from 'Components';
import './VolumeList.scss';

const Volume = props => {
  const { volume, index, slug } = props;

  const [displayChapters, setDisplayChapters] = useState(
    index === 0 ? true : false
  );
  const history = useHistory();
  const navigate = history.push;
  const today = dayjs();
  const { is } = useResponsive();
  const isDesktop = is('lg');
  const chapterStart =
    volume.chapterStart ||
    (volume.chapters.length > 0 ? volume.chapters[0].number : 0);
  const chapterEnd =
    volume.chapterEnd ||
    (volume.chapters.length > 1
      ? volume.chapters[volume.chapters.length - 1].number
      : 0);

  const admin = dayjs(volume.releaseDate).isAfter(today);

  const toggleChapters = () => setDisplayChapters(!displayChapters);
  const releasedChapters = volume.chapters.filter(chapter =>
    dayjs(chapter.releaseDate).isBefore()
  );
  return (
    <>
      <div className="volume-toggle clickable" onClick={toggleChapters}>
        {admin ? (
          <div className="admin-area">
            <Pill backgroundColor="#e11f7b">Admin uniquement</Pill>
          </div>
        ) : null}
        <VolumeCard
          style={{ opacity: releasedChapters.length > 0 ? 1 : 0.5 }}
          open={displayChapters}
          cover={getResizedUrl(volume.thumbnail.url, {
            width: withPixelDensity(100),
          })}
          title={volume.label ? volume.label : `Tome ${volume.number}`}
          subtitle={`Chapitres ${chapterStart} à ${chapterEnd}  `}
          size={isDesktop ? 'large' : 'small'}
          description={volume.description}
          backgroundImage={getResizedUrl(volume.thumbnail.url, {
            width: withPixelDensity(100),
          })}
          action={
            // on permet la navigation vers la page volume uniquement si l'ean est défini
            volume.ean13
              ? e => {
                  e.stopPropagation();
                  navigate('/volume/' + volume.ean13);
                }
              : null
          }
        />
      </div>
      <ChapterList
        chapters={volume.chapters}
        displayChapters={displayChapters}
        slug={slug}
      />
    </>
  );
};

const VolumeList = props => {
  const { volumes, slug } = props;
  const [seeMore, setSeeMore] = useState(false);

  const visibleVolumes = seeMore ? volumes : volumes.slice(0, 5);
  return (
    <div className="ChapterList hide-scrollbar">
      {visibleVolumes.map((volume, index) => (
        <Volume key={index} index={index} volume={volume} slug={slug} />
      ))}

      {volumes.length > 5 && (
        <Separator>
          <Button
            onClick={() => setSeeMore(!seeMore)}
            label={seeMore ? 'Voir moins' : 'Voir plus'}
          ></Button>
        </Separator>
      )}
    </div>
  );
};

VolumeList.defaultProps = {};

export default VolumeList;
