import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Button, RadioButton } from 'design-system';
import gql from 'graphql-tag';
import { useMutation, useResponsive, useProfile, useQuery } from 'hooks';
import { Page, ResponsiveText } from 'UI';
import { range } from 'utils';
import './FavoriteCategories.scss';
import classnames from 'classnames';

const GQL_GET_CATEGORIES = gql`
  query GetCategories($filters: CategoryFilter) {
    categories(filters: $filters) {
      _id
      slug
      isFavorite
      label
      level
    }
  }
`;
const GQL_UPDATE_ME = gql`
  mutation updateMe($input: ProfileInput!) {
    updateMe(input: $input) {
      _id
      isActive
      favoriteCategories {
        _id
        label
        level
      }
      email
      avatar {
        url
      }
    }
  }
`;

export default function FavoriteCategories() {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const { refreshProfile, profile } = useProfile();
  const history = useHistory();
  const navigate = history.push;
  const { is } = useResponsive();
  const isTab = !is('md');
  const { data } = useQuery(GQL_GET_CATEGORIES, { variables: {} });
  const categories =
    data?.categories
      .filter(category => category.level < 2 && category.isFavorite)
      .map(category => category.label) || [];

  const handleClick = cat => {
    if (selectedCategories.length < 3 && !selectedCategories.includes(cat))
      setSelectedCategories(current => [...current, cat]);
    if (selectedCategories.length <= 3 && selectedCategories.includes(cat))
      setSelectedCategories(selectedCategories.filter(item => item !== cat));
  };

  const [updateMe] = useMutation(GQL_UPDATE_ME);
  useEffect(() => {
    if (profile?.favoriteCategories)
      setSelectedCategories(
        profile.favoriteCategories.map(category => category.label)
      );
  }, [profile?.favoriteCategories]);

  const onSubmit = async () => {
    const categoriesObject = data?.categories.filter(cat =>
      selectedCategories.includes(cat.label)
    );
    const favoriteCategories = categoriesObject.map(
      ({ _id, label, level }) => ({ _id, label, level })
    );

    await updateMe({
      variables: {
        input: {
          favoriteCategories: favoriteCategories,
        },
      },
    });
    refreshProfile();
    navigate('/');
  };

  return (
    <Page id="FavoritesCategories">
      <ResponsiveText type="display" className="pageTitle">
        Sélectionne tes 3 catégories préférées
      </ResponsiveText>
      <ResponsiveText type="text" size="extra-large">
        Pour obtenir nos meilleures recommandations !
      </ResponsiveText>
      <div className="categories">
        {categories.map((category, index) => (
          <RadioButton
            key={index}
            className={classnames('categoryCard', {
              selectedCard: selectedCategories.includes(category),
            })}
            onClick={() => handleClick(category)}
          >
            <ResponsiveText>{category}</ResponsiveText>
          </RadioButton>
        ))}
        {!isTab &&
          range(2).map(key => (
            <div
              key={key}
              className="categoryCard"
              style={{ minHeight: 0, height: 0, marginTop: 0, marginBottom: 0 }}
            />
          ))}
      </div>
      <Button
        fullWidth={isTab}
        variant="primary"
        label="Je continue"
        onClick={() => onSubmit()}
      />
    </Page>
  );
}
