import React from 'react';

import {
  InstantSearch,
  Configure,
  useSortBy,
  useSearchBox,
  useHits,
} from 'react-instantsearch';
import { Link } from 'react-router-dom';

import { MangaTile, PlaceholderShape } from 'design-system';

import { useBundles, useResponsive } from 'hooks';
import { searchClient, range, getResizedUrl, withPixelDensity } from 'utils';
import { ResponsiveText } from 'UI';

import { Dropdown, FavoriteButtonConnected } from 'Components';

import './MangaSearch.scss';

function MangaSearch({ filters = '' }) {
  const { getAlgoliaBundlesFilters } = useBundles();
  const formattedFilters = getAlgoliaBundlesFilters(filters);
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}Mangas_Filter_Popularity`}
    >
      <Configure
        filters={formattedFilters}
        hitsPerPage={500}
        attributesToRetrieve={[
          'title',
          'thumbnail',
          'categories',
          'slug',
          '_id',
        ]}
        responseFields={['hits']}
        attributesToHighlight={[]}
      />
      <div id="MangaSearch">
        <div className="dropdown">
          <ResponsiveText className="dropdown-label">
            Trier par :
          </ResponsiveText>
          <SortBy />
        </div>
        <Results />
      </div>
    </InstantSearch>
  );
}

const SortBy = () => {
  const items = [
    {
      value: `${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}Mangas_Filter_Popularity`,
      label: 'Popularité',
    },
    {
      value: `${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}Mangas`,
      label: 'Ordre alphabétique',
    },
    {
      value: `${process.env.REACT_APP_ALGOLIA_INDEX_PREFIX}Mangas_Filter_ChapterCount`,
      label: 'Nombre de chapitres',
    },
  ];
  const { refine } = useSortBy({
    items,
  });
  return (
    <Dropdown
      selectDefaultValue={items}
      selectOptions={items}
      link={item => {
        refine(item.value);
      }}
    />
  );
};

const Results = () => {
  const { hits } = useHits();
  const { isSearchStalled: loading } = useSearchBox();
  const { is } = useResponsive();
  if (!loading && hits.length === 0)
    return (
      <ResponsiveText type="display" bold size="small">
        Aucun manga disponible dans cette catégorie
      </ResponsiveText>
    );
  return (
    <ul>
      {loading &&
        Array.from({ length: 10 }, (_, index) => (
          <li key={index}>
            <MangaTile loading={true} size={getTileSize(is)} />
          </li>
        ))}
      {hits.map(hit => (
        <li key={hit.slug}>
          <MangaSearchTile hit={hit} />
        </li>
      ))}
      {range(5).map(key => (
        <li key={key}>
          <PlaceholderShape
            style={{
              width: getTileSizeInPixels(is, true),
              height: 1,
              visibility: 'hidden',
            }}
          />
        </li>
      ))}
    </ul>
  );
};

function getTileSize(is) {
  if (is('xl')) return 'large';
  if (is('sm')) return 'medium';
  return 'small';
}

function getTileSizeInPixels(is, ignorePixelDensity = false) {
  const pixelDensity = ignorePixelDensity ? a => a : withPixelDensity;
  if (is('xl')) return pixelDensity(232);
  if (is('sm')) return pixelDensity(180);
  return pixelDensity(140);
}

function MangaSearchTile({ hit }) {
  const { is } = useResponsive();
  return (
    <Link
      to={`/lire/${hit.slug}`}
      className="MangaCover skeleton-shape-bg clickable"
    >
      <MangaTile
        picture={getResizedUrl(hit.thumbnail, {
          width: getTileSizeInPixels(is),
        })}
        title={hit.title}
        size={getTileSize(is)}
        subtitle={`${hit.categories.slice(0, 2).join(' · ')}`}
      >
        <FavoriteButtonConnected mangaId={hit._id} size="small" noLabel />
      </MangaTile>
    </Link>
  );
}

export default MangaSearch;
