import React, { useState } from 'react';
import { Button, Text } from 'design-system';
import { Link } from 'react-router-dom';
import './LandingCards.scss';
import gql from 'graphql-tag';
import { loadStripe } from '@stripe/stripe-js';
import { useFeatureFlags, useMutation } from 'hooks';

const GET_VOUCHER_CHECKOUT = gql`
  mutation checkoutVoucher($productId: ID!) {
    voucherCheckoutSession(productId: $productId) {
      id
      error
    }
  }
`;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function StripeCTA({ productId, ...buttonProps }) {
  const [error, setError] = useState('');
  const [hasLoader, setHasLoader] = useState(false);

  const [checkout] = useMutation(GET_VOUCHER_CHECKOUT, {
    onCompleted: async data => {
      const stripe = await stripePromise;
      const { id, error } = data.voucherCheckoutSession || {};
      if (id) {
        setTimeout(async () => {
          await stripe.redirectToCheckout({
            sessionId: id,
          });
        }, 1500);
      } else if (error) {
        setError(error);
      }
    },
  });
  if (error)
    return (
      <div className="error">
        <p>Une erreur a eu lieu:</p>
        <p>{error}</p>
      </div>
    );
  return (
    <Button
      {...buttonProps}
      loading={hasLoader}
      disabled={hasLoader}
      onClick={() => {
        setHasLoader(true);
        checkout({
          variables: { productId: productId },
        });
      }}
    />
  );
}

const LandingCards = ({ withButton }) => {
  const flags = useFeatureFlags(['gift_cards']);

  const offers = JSON.parse(flags?.gift_cards?.value || '[]');
  return (
    <div id="LandingCards">
      <div className="CardsComponent">
        {offers.map(offer => (
          <div
            key={offer.stripe_price_id}
            className="priceBloc"
            style={{
              background: `linear-gradient(${offer.gradient})`,
            }}
          >
            <Text type="display" size="small" bold>
              {offer.title}
            </Text>
            <div className="tag">{offer.pill}</div>
            <img src={offer.image} height="116" alt="card preview" />
            <Text className="tag tagWhite textDarkBlue">{offer.subtitle}</Text>
            <Text className="priceText">{offer.price}</Text>

            <StripeCTA
              productId={offer.stripe_price_id}
              className="btnOffer"
              size="large"
              label={offer.cta}
              variant="primary"
            />

            {offer.secondary_cta && (
              <a href={offer.secondary_cta_link}>
                <Button label={offer.secondary_cta} />
              </a>
            )}
            {offer.footer && (
              <Text className="subText" size="small">
                {offer.footer}
              </Text>
            )}
          </div>
        ))}
      </div>
      {withButton && (
        <Link to="/carte-cadeau">
          <Button
            id="infoBtn"
            label="Comment ça marche ?"
            variant="primary"
            size="large"
          />
        </Link>
      )}
    </div>
  );
};

export default LandingCards;
