import React, { useState } from 'react';
import classnames from 'classnames';
import Icon from '@mdi/react';
import { mdiCheck, mdiArrowLeft } from '@mdi/js';
import { Input, Button, Text } from 'design-system';

import { FloatingMenu } from 'UI';
import { useProfile } from 'hooks';
import { GraphqlShield } from 'utils';

import './ChangePasswordSlide.scss';

const PasswordSuccess = () => {
  return (
    <div className="password-success">
      <Icon path={mdiCheck} />
      <Text>Mot de passe modifié !</Text>
    </div>
  );
};

const ChangePassword = props => {
  const { navigation, errors, className } = props;
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [success, setSuccess] = useState(false);
  const { updateMe } = useProfile();

  async function handleSubmit(e) {
    e.preventDefault();
    await updateMe({
      variables: {
        input: {
          password: {
            new: newPass,
            old: oldPass,
          },
        },
      },
    });
    setSuccess(true);
  }

  const oldPasswordError = errors.includes('Invalid password');
  const newPasswordError = errors.includes(
    'Profile validation failed: password: should be at least 8 characters'
  );

  return (
    <div className={classnames(className, 'slide', 'change-password-slide')}>
      <FloatingMenu.Header
        className="clickable"
        onClick={() => navigation.pop()}
      >
        <span className="back-icon">
          <Icon path={mdiArrowLeft} />
        </span>
        Gérer mon mot de passe
      </FloatingMenu.Header>
      <FloatingMenu.Body>
        {success && <PasswordSuccess />}
        {!success && (
          <div className="form">
            <Input
              type="password"
              label="Mot de passe actuel"
              placeholder="••••••••••••••••"
              highlight={oldPasswordError ? 'error' : null}
              value={oldPass}
              onChange={e => setOldPass(e.target.value)}
            />
            {oldPasswordError && (
              <Text className="error" mobile>
                Mot de passe incorrect
              </Text>
            )}
            <Input
              type="password"
              label="Nouveau mot de passe"
              placeholder="••••••••••••••••"
              highlight={newPasswordError ? 'error' : null}
              value={newPass}
              onChange={e => setNewPass(e.target.value)}
            />
            {newPasswordError && (
              <Text className="error" mobile>
                Ton nouveau mot de passe doit faire au minimum 8 caractères
              </Text>
            )}
            <Button
              label="Valider"
              variant="primary"
              size="large"
              fullWidth
              onClick={handleSubmit}
            />
          </div>
        )}
      </FloatingMenu.Body>
    </div>
  );
};

function ChangePasswordSlide(props) {
  return (
    <GraphqlShield>
      {(errors = []) => <ChangePassword {...props} errors={errors} />}
    </GraphqlShield>
  );
}

export default ChangePasswordSlide;
