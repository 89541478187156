import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import classnames from 'classnames';
import { Text } from 'design-system';

import { useMutation } from 'hooks';
import './PaymentMethodSlide.scss';

const GQL_GET_STRIPE_BILLING_SESSION = gql`
  mutation createBillingSession {
    createBillingSession {
      url
      error
    }
  }
`;

const PaymentMethodSlide = props => {
  const { active } = props;
  const [createStripeSession] = useMutation(GQL_GET_STRIPE_BILLING_SESSION, {
    onCompleted: data => {
      if (data.createBillingSession.url)
        window.location.href = data.createBillingSession.url;
    },
  });
  useEffect(() => {
    let timeout = null;
    if (active) {
      timeout = setTimeout(() => {
        createStripeSession();
      }, 1000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);
  return (
    <div className={classnames(props.className, 'slide', 'stripe-redirect')}>
      <span className="stripe-loader">
        <img
          src="https://cdn.mangas.io/images/stripe-loader.svg"
          width="191"
          height="63"
          alt="stripe"
        />
        <svg
          className="spinner"
          width="49"
          height="49"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="path"
            fill="none"
            strokeWidth="6"
            strokeLinecap="square"
            cx="33"
            cy="33"
            r="30"
          ></circle>
        </svg>
      </span>
      <Text size="small">
        Redirection vers notre partenaire pour les paiements sécurisés…
      </Text>
    </div>
  );
};

export default PaymentMethodSlide;
