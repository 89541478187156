import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Drawer.scss';

const Drawer = ({ children, className, ...props }) => (
  <div className={classnames('mio-drawer', className)} {...props}>
    {children}
  </div>
);

function Left(props) {
  return <div className="left" {...props} />;
}
function Right(props) {
  return <div className="right" {...props} />;
}

Drawer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Drawer.defaultProps = {};

export default Object.assign(Drawer, {
  Left,
  Right,
});
