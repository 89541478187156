import React, { useState } from 'react';
import classnames from 'classnames';
import Icon from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';

import { range, trackHandler } from 'utils';
import { PrismicRichText, usePrismicDocumentByUID } from '@prismicio/react';
import { PlaceholderShape } from 'design-system';

import './LandingFaq.scss';

const QuestionWrapper = ({ question, answer, startOpen, loading }) => {
  const [isOpen, setIsOpen] = useState(startOpen);

  return (
    <li className="faq-row">
      <div
        role="button"
        className={classnames('faq-question', {
          active: isOpen,
        })}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="content">
          <h3 className="title">
            {loading && (
              <PlaceholderShape
                style={{ height: '20px', width: '240px' }}
                as="span"
              />
            )}
            {!loading && question}
          </h3>
          <Icon className="close" path={mdiChevronDown} />
        </div>
      </div>
      <div
        className={classnames('faqAnswer', {
          active: isOpen,
        })}
      >
        <div className="content">
          {loading &&
            [200, 280, 170].map(width => (
              <PlaceholderShape
                key={width}
                style={{ height: 20, width }}
                as="span"
              />
            ))}
          {!loading && <PrismicRichText field={answer} />}
        </div>
      </div>
    </li>
  );
};

const LandingFaq = ({ title, page_name, displayContact = true }) => {
  const [document, { state }] = usePrismicDocumentByUID('faq', page_name);
  const loading = state !== 'loaded';
  const faq = !loading
    ? document.data.questions
    : range(3).map(() => ({ question: '', answer: '' }));

  return (
    <div id="LandingFaq" className="">
      <div className="section-container">
        <div className="content-wrapper">
          <h2 className="faq-title">{title}</h2>
          <div className="faq-container">
            <ul className="faq">
              {faq.map((item, index) => (
                <QuestionWrapper
                  key={index}
                  question={item.question}
                  answer={item.answer}
                  startOpen={index === 0}
                  loading={loading}
                />
              ))}
            </ul>
            {displayContact && (
              <p className="faq-footer">
                Tu n’as pas trouvé ta réponse ?{' '}
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="mailto:support@mangas.io?subject=💌 SAV / Service clients"
                  onClick={e =>
                    trackHandler(e, 'Contact Clicked', {
                      type: 'support',
                      source: 'landing',
                    })
                  }
                >
                  contacte nous
                </a>
                .
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingFaq;
