import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Link, useHistory, useParams } from 'react-router-dom';
import Icon from '@mdi/react';
import {
  mdiArrowLeft,
  mdiClose,
  mdiBookOpenPageVariant,
  mdiPlay,
} from '@mdi/js';
import { Bloc, Button, MangaTile, Text } from 'design-system';

import { useQuery, useResponsive, useFlags } from 'hooks';
import { ResponsiveColumns, BackLink, Mask, Information, Page, Tag } from 'UI';
import { dayjs, getResizedUrl, withPixelDensity } from 'utils';
import {
  Affiliation,
  CarrouselWrapper,
  ChapterList,
  Dropdown,
  Footer,
  NotFound,
  SEO,
  ShareButton,
} from 'Components';

import './VolumePage.scss';

const { Row } = Information;

function getTileSize(is) {
  if (is('xl')) return 'large';
  if (is('sm')) return 'medium';
  return 'small';
}

function getTileSizeInPixels(is, ignorePixelDensity = false) {
  const pixelDensity = ignorePixelDensity ? a => a : withPixelDensity;
  if (is('xl')) return pixelDensity(232);
  if (is('sm')) return pixelDensity(180);
  return pixelDensity(140);
}

const GQL_GET_MANGAV2 = gql`
  query GetMangaV2($ean: String) {
    manga(ean: $ean) {
      _id
      slug
      title
      isOngoing
      alternativeTitles
      description
      age
      publishers {
        publisher {
          _id
          slug
          name
          countryCode
          thumbnail {
            url
          }
        }
        releaseDate
      }
      chapterCount
      categories {
        label
        slug
        isFavorite
        level
      }
      banner {
        url
      }
      thumbnail {
        url
      }
      authors {
        _id
        slug
        name
      }
      volumes {
        _id
        title
        ean13
        description
        number
        publicationDate
        releaseDate
        thumbnail {
          url
          pos_x
          pos_y
        }
        chapterStart
        chapterEnd
        chapterCount
        chapters {
          _id
          title
          number
          isRead
          isSeparator
          isBonus
          access
          releaseDate
          pageCount
        }
      }
    }
  }
`;

export default function VolumePage(props) {
  const { ean } = useParams();
  const { data, loading } = useQuery(GQL_GET_MANGAV2, {
    variables: { ean },
  });
  const { flag: noPromoFlag } = useFlags('NO_PROMO');

  const { is } = useResponsive();
  const isDesktop = is('lg');
  const history = useHistory();
  const navigate = history.push;
  const { manga } = data || {};
  const { volumes } = manga || [];
  const [displayAffiliationModal, setAffiliationModal] = useState(false);
  const toggleAffiliationModal = () =>
    setAffiliationModal(!displayAffiliationModal);

  if (!manga && !loading) return <NotFound />;

  if (loading) return <div id="VolumePage" />;
  //TO DO ^^^^

  const currentVolume = manga.volumes.find(volume => volume.ean13 === ean);

  const sortedVolumes = [...manga.volumes].sort((a, b) =>
    dayjs(a.publicationDate).isBefore(b.publicationDate) ? -1 : 1
  );
  const firstVolumePublication = [...sortedVolumes].shift();
  const lastVolumePublication = [...sortedVolumes].pop();

  const publisher_vo = manga.publishers.find(
    ({ publisher }) => publisher.countryCode !== 'fr'
  );
  const publisher_fr = manga.publishers.find(
    ({ publisher }) => publisher.countryCode === 'fr'
  );

  function lastVolumeDate(elem) {
    if (manga.isOngoing) return 'En cours';
    else return dayjs(elem.publicationDate).format('YYYY');
  }

  const selectOptions = manga.volumes.map(volume => ({
    value: volume.number,
    label: <div>Tome {volume.number}</div>,
    isDisabled: dayjs(volume.releaseDate).isAfter(dayjs()),
  }));

  const selectDefaultValue = selectOptions.find(
    elem => elem.value === currentVolume.number
  );

  return (
    <>
      <Page id="VolumePage">
        <SEO
          title={`${manga.title} - Lire le tome ${currentVolume.number} en VF - Traduction officielle`}
          description={`Volume ${currentVolume.number} de ${manga.title} en ligne et sans pub sur Mangas.io, l'alternative légale au scan ! ${currentVolume.description}`}
          image={currentVolume.thumbnail}
        />
        <div className="volume-header">
          <BackLink
            fallback="/"
            render={hasPreviousPage => (
              <Button
                variant={isDesktop ? 'secondary' : 'text'}
                label={
                  isDesktop ? (hasPreviousPage ? 'Retour' : 'Accueil') : null
                }
                size="large"
                icon={<Icon path={mdiArrowLeft} />}
              />
            )}
          />
          <ShareButton />
        </div>

        <Bloc>
          <ResponsiveColumns invert oneColumn={!isDesktop}>
            <div
              className="dropdown-container"
              renderPosition="main"
              style={{ display: isDesktop ? 'block' : 'none' }}
            >
              {isDesktop && (
                <Dropdown
                  selectDefaultValue={selectDefaultValue}
                  link={row => {
                    navigate(`/volume/${manga.volumes[row.value - 1].ean13}`);
                  }}
                  selectOptions={selectOptions}
                />
              )}
            </div>
            <div renderPosition="main">
              <div className="header-container">
                {!isDesktop && (
                  <CarrouselWrapper>
                    {volumes.map((tome, index) => (
                      <Link key={index} to={`/volume/${tome.ean13}`}>
                        <Button
                          tome={tome}
                          label={`Tome ${tome.number}`}
                          size="small"
                        />
                      </Link>
                    ))}
                  </CarrouselWrapper>
                )}
                <div className="volume-header-title">
                  <Text id="manga-title" type="display" size="medium" as="h1">
                    {manga.title}
                  </Text>
                  <Text id="tome-subtitle" type="text" size="large" as="h2">
                    Tome {currentVolume.number} par{' '}
                    <Link
                      to={`/author/${manga.authors[0].slug}`}
                      id="author-name"
                      key={manga.authors[0]._id}
                    >
                      {manga.authors[0].name}
                    </Link>
                  </Text>
                </div>
              </div>
            </div>
            <div className="volume-header-buttons" renderPosition="sidebar">
              <div className="cover-img-container">
                <img
                  id="cover-img"
                  src={getResizedUrl(currentVolume.thumbnail.url, {
                    width: withPixelDensity(330),
                  })}
                  alt="Volume thumbnail"
                />
              </div>
              <div className="buttons">
                <Link
                  to={`/lire/${manga.slug}/${currentVolume.chapters[0].number}`}
                >
                  <Button
                    id="button-header-volume"
                    size="large"
                    variant="primary"
                    label="Lire en ligne"
                    icon={<Icon path={mdiPlay} />}
                  />
                </Link>
                {!noPromoFlag && (
                  <Button
                    id="btn-affiliation-modal"
                    size="large"
                    icon={<Icon path={mdiBookOpenPageVariant} />}
                    variant="secondary"
                    label="Acheter les volumes"
                    onClick={toggleAffiliationModal}
                  />
                )}
              </div>

              {displayAffiliationModal && (
                <Mask onClose={() => setAffiliationModal(false)} withOverlay>
                  <div className="affiliation-volume-modal">
                    <Bloc
                      className="affiliation-bloc"
                      //renderPosition="sidebar"
                      title="Acheter les volumes"
                      title_right={
                        <span id="icon-affiliation-modal">
                          <Icon
                            onClick={() => setAffiliationModal(false)}
                            path={mdiClose}
                          />
                        </span>
                      }
                    >
                      <Affiliation
                        manga={manga}
                        volume={volumes}
                        tomevolume={currentVolume.number}
                      />
                    </Bloc>
                  </div>
                </Mask>
              )}
            </div>

            <Information renderPosition="main">
              <Row title={`Résumé tome ${currentVolume.number}`}>
                {currentVolume.description}
              </Row>
              <Row title="Catégorie(s)">
                <div className="category-tags">
                  {manga.categories.map((item, i) => (
                    <Tag
                      isFavorite={item.isFavorite}
                      slug={item.slug}
                      key={i}
                      value={item}
                    >
                      {item.label}
                    </Tag>
                  ))}
                </div>
              </Row>
              {manga.age && <Row title="Age recommandé">{manga.age}</Row>}
            </Information>

            <Information renderPosition="main">
              <Row title="Détails de publication" as="h2" />
              {publisher_vo && (
                <Row title="Publication originale">
                  <Link to={`/publisher/${publisher_vo.publisher.slug}`}>
                    {publisher_vo.publisher.name}
                  </Link>
                  | {dayjs(publisher_vo.releaseDate).format('YYYY')}
                </Row>
              )}
              {publisher_fr && (
                <Row title="Publication française">
                  <Link to={`/publisher/${publisher_fr.publisher.slug}`}>
                    {publisher_fr.publisher.name}
                  </Link>
                  | {dayjs(publisher_fr.releaseDate).format('YYYY')}
                </Row>
              )}
              {firstVolumePublication.publicationDate && lastVolumePublication && (
                <Row title="Années de publication">
                  {dayjs(firstVolumePublication.publicationDate).format('YYYY')}{' '}
                  - {lastVolumeDate(lastVolumePublication)}
                </Row>
              )}
              {manga.alternativeTitles.length > 0 && (
                <Row title="Autres noms">
                  {manga.alternativeTitles.join(', ')}
                </Row>
              )}
            </Information>
          </ResponsiveColumns>
        </Bloc>
        <Bloc
          className="volume-chapters-bloc"
          title={`Chapitres du tome ${currentVolume.number}`}
        >
          <ChapterList chapters={currentVolume.chapters} slug={manga.slug} />
        </Bloc>

        <Bloc className="volume-tomes-bloc" title="Tous les tomes en ligne">
          <div className="volume-mangas-list">
            {volumes.map(volume => (
              <Link key={volume.ean13} to={`/volume/${volume.ean13}`}>
                <MangaTile
                  id="manga-tile"
                  size={getTileSize(is)}
                  loading={true}
                  picture={getResizedUrl(volume.thumbnail.url, {
                    width: getTileSizeInPixels(is),
                  })}
                  title={volume.title}
                />
              </Link>
            ))}
          </div>
        </Bloc>
      </Page>
      <Footer />
    </>
  );
}
