import React from 'react';
import { Redirect, useParams, Route, useRouteMatch } from 'react-router-dom';
import gql from 'graphql-tag';
import { Button } from 'design-system';

import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import { useQuery, useResponsive } from 'hooks';
import { Page, ResponsiveText, BackLink } from 'UI';
import { capitalize } from 'utils';
import { Footer, PreFooterTrial, SEO, MangaSearch } from 'Components';
import './SubCategories.scss';

const GQL_GET_CATEGORIES = gql`
  query GetCategories($filters: CategoryFilter) {
    categories(filters: $filters) {
      _id
      slug
      isFavorite
      label
      level
      description
      metaTitle
      metaDesc
      title
      subtitle
      shortDescription
      subtitleSecondary
    }
  }
`;

function SubCategories() {
  const { data, loading } = useQuery(GQL_GET_CATEGORIES, {});
  const { is } = useResponsive();
  const isMedium = is('md');
  const { categories } = data || {};
  const { category } = useParams();
  const cat = categories ? categories.find(c => c.slug === category) : null;
  if (!loading && !cat && category) {
    return <Redirect to="/manga-en-ligne/" noThrow />;
  }

  return (
    <>
      <Page id="SubCategories">
        <div className="page-header">
          <BackLink
            fallback="/"
            render={hasPreviousPage => (
              <Button
                variant={isMedium ? 'secondary' : 'text'}
                label={
                  isMedium ? (hasPreviousPage ? 'Retour' : 'Accueil') : null
                }
                size="large"
                icon={<Icon id="back-icon" path={mdiArrowLeft} />}
              />
            )}
          />
        </div>
        <SEO
          title={
            cat?.metaTitle
              ? cat.metaTitle
              : 'Nos titres manga en ligne et à l’abonnement | Mangas.io'
          }
          description={
            cat?.metaDesc
              ? cat.metaDesc
              : "Lis tes mangas en ligne sur Mangas.io ! Découvre des milliers de chapitres en lecture légale et sans pub pour le prix d'un manga par mois."
          }
        />

        <div className="SubCategories-container">
          <div className="SubCategories-header">
            <ResponsiveText
              as="h1"
              className="categoryTitle"
              type="display"
              bold
              size="medium"
            >
              {cat?.label && capitalize(cat.label)}
            </ResponsiveText>
            <ResponsiveText
              as="h2"
              type="display"
              size="small"
              className="categorySubtitle"
            >
              {cat?.subtitle}
            </ResponsiveText>
            <ResponsiveText
              as="p"
              type="text"
              size="medium"
              className="categoryShortDescription"
            >
              {cat?.shortDescription}
            </ResponsiveText>
          </div>

          <MangaSearch filters={cat ? `categories:"${cat.label}"` : ''} />
        </div>
      </Page>
      <PreFooterTrial />
      <Footer />
    </>
  );
}

function SubCategoriesRouter() {
  const match = useRouteMatch();
  return (
    <Route exact path={[`${match.path}/:category`]}>
      <SubCategories />
    </Route>
  );
}

export default SubCategoriesRouter;
