import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './bloc.scss';

import Text from '../Text/Text';

const Bloc = ({
  title,
  title_right,
  invertTitles,
  pre_header,
  subtitle,
  mobile,
  className,
  children,
  ...props
}) => (
  <div className={classnames('mio-bloc', { mobile }, className)} {...props}>
    {pre_header}
    {(!!title || !!title_right || !!subtitle) && (
      <div className={classnames('header', { invertTitles })}>
        <div className="title_bloc">
          <Text as="h2" type="display" size="small" bold mobile={mobile}>
            {title}
          </Text>
          {title_right}
        </div>
        <Text size="small" mobile={mobile} className="subtitle">
          {subtitle}
        </Text>
      </div>
    )}
    <div className="content">{children}</div>
  </div>
);

Bloc.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  mobile: PropTypes.bool,
  pre_header: PropTypes.element,
  title_right: PropTypes.element,
  invertTitles: PropTypes.bool,
};

Bloc.defaultProps = {
  invertTitles: false,
  mobile: false,
  pre_header: null,
  title_right: null,
};

export default Bloc;
