import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './toast.scss';

/**
 * Toast component
 */
const Toast = ({ variant, ...props }) => {
  return (
    <span
      className={classnames('mio-toast', {
        [variant]: true,
      })}
      {...props}
    />
  );
};

Toast.propTypes = {
  variant: PropTypes.oneOf(['success', 'error', 'warning']),
};

Toast.defaultProps = {
  variant: 'success',
};

export default Toast;
