import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Checkbox from '../Checkbox/Checkbox';

import './RadioBox.scss';

function RadioBox({ className, variant, mobile, children, ...props }) {
  const _children = React.Children.toArray(children);
  const headerComponents = _children.filter((child) => child.props.isHeader);
  const innerComponents = _children.filter((child) => !child.props.isHeader);
  return (
    <div
      className={classnames('mio-radio-box', className, variant, { mobile })}
    >
      {headerComponents}
      <Checkbox {...props}>
        <div className="mio-radio-box-content">{innerComponents}</div>
      </Checkbox>
    </div>
  );
}

function Header({ className, isHeader, ...props }) {
  return (
    <div className={classnames('mio-radio-box-header', className)} {...props} />
  );
}
Header.defaultProps = {
  isHeader: true,
};

function Center({ className, ...props }) {
  return (
    <div className={classnames('mio-radio-box-center', className)} {...props} />
  );
}
function Right({ className, ...props }) {
  return (
    <div className={classnames('mio-radio-box-right', className)} {...props} />
  );
}

RadioBox.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['orange', 'blue', 'default', 'promo']),
  mobile: PropTypes.bool,
  children: PropTypes.node,
};

RadioBox.defaultProps = {
  variant: 'default',
  mobile: false,
};

export default Object.assign(RadioBox, {
  Center,
  Right,
  Header,
});
