import React from 'react';

import { Button, Pill } from 'design-system';
import { Link } from 'react-router-dom';

import { ResponsiveText } from 'UI';

import './PricingCardHighlight.scss';

function PricingCardHighlight({ ctaLink, highlight }) {
  return (
    <div
      className="pricing-card-highlight"
      style={
        highlight.background
          ? { backgroundImage: `url(${highlight.background}` }
          : {}
      }
    >
      {highlight.header && (
        <div
          className="pricing-card-highlight-header"
          style={
            highlight.header.background
              ? { backgroundImage: `url(${highlight.header.background}` }
              : {}
          }
        >
          {highlight.header.title && (
            <ResponsiveText type="display" size="medium">
              {highlight.header.title}
            </ResponsiveText>
          )}
          {highlight.header.title && (
            <ResponsiveText type="display" size="small">
              {highlight.header.subtitle}
            </ResponsiveText>
          )}
          {highlight.header.icon && (
            <img alt="" src={highlight.header.icon}></img>
          )}
        </div>
      )}
      {highlight.pill && (
        <Pill size="large" backgroundColor="white">
          {highlight.pill}
        </Pill>
      )}
      {highlight.price && (
        <div className="pricing-card-highlight-price">
          <ResponsiveText type="display" size="large" bold>
            {highlight.price.amount}
          </ResponsiveText>
          <ResponsiveText type="display" size="small">
            {highlight.price.interval}
          </ResponsiveText>
        </div>
      )}
      {highlight.perks && (
        <div className="pricing-card-highlight-perks">
          {highlight.perks.map((perk, key) => (
            <ResponsiveText key={key} size="extra-large">
              {perk}
            </ResponsiveText>
          ))}
        </div>
      )}
      {highlight.cta && (
        <Link to={ctaLink}>
          <Button variant="primary" size="large" label={highlight.cta} />
        </Link>
      )}
      {highlight.footer && (
        <ResponsiveText size="large">{highlight.footer}</ResponsiveText>
      )}
    </div>
  );
}

export default PricingCardHighlight;
