import React from 'react';
import { Redirect, useParams, Route, useRouteMatch } from 'react-router-dom';
import gql from 'graphql-tag';
import { PlaceholderShape } from 'design-system';

import { useQuery } from 'hooks';
import { Page, ResponsiveText, Tag } from 'UI';
import classnames from 'classnames';
import { capitalize } from 'utils';
import { Footer, PreFooterTrial, SEO, MangaSearch } from 'Components';
import './Library.scss';

const GQL_GET_CATEGORIES = gql`
  query GetCategories($filters: CategoryFilter) {
    categories(filters: $filters) {
      _id
      slug
      isFavorite
      label
      level
      description
      metaTitle
      metaDesc
      title
      subtitle
      shortDescription
      subtitleSecondary
    }
  }
`;

const PlaceholderTag = ({ width = 50 }) => (
  <PlaceholderShape
    style={{
      height: 20,
      background: 'rgba(255, 255, 255, 0.15)',
      marginRight: 10,
      width,
    }}
  />
);

function PublicLibrary() {
  const { data, loading } = useQuery(GQL_GET_CATEGORIES, {});
  const { categories = [] } = data || {};
  const { category } = useParams();

  const cat = categories ? categories.find(c => c.slug === category) : null;
  if (!loading && !cat && category) {
    return <Redirect to="/manga-en-ligne/" noThrow />;
  }

  return (
    <>
      <Page id="LibraryPage">
        <SEO
          title={
            cat?.metaTitle
              ? cat.metaTitle
              : 'Nos titres manga en ligne et à l’abonnement | Mangas.io'
          }
          description={
            cat?.metaDesc
              ? cat.metaDesc
              : "Lis tes mangas en ligne sur Mangas.io ! Découvre des milliers de chapitres en lecture légale et sans pub pour le prix d'un manga par mois."
          }
        />

        <div className="LibraryPage-container">
          <div className="LibraryPage-header">
            <ResponsiveText type="display" bold size="small">
              Catégories populaires
            </ResponsiveText>
            <nav className="LibraryPage-nav hide-scrollbar">
              {loading && (
                <>
                  <PlaceholderTag width={130} />
                  <PlaceholderTag width={70} />
                  <PlaceholderTag width={60} />
                  <PlaceholderTag width={80} />
                </>
              )}
              {!loading && (
                <>
                  <Tag
                    className={classnames('link', {
                      active: category === undefined,
                    })}
                    isFavorite
                    slug=" "
                  >
                    Tous les mangas
                  </Tag>
                  {categories
                    .filter(c => c.isFavorite)
                    .sort((a, b) => a.level - b.level)
                    .map(cat => (
                      <Tag
                        key={cat.slug}
                        className={classnames('link', {
                          active: category === `${cat.slug}`,
                        })}
                        isFavorite={cat.isFavorite}
                        slug={cat.slug}
                      >
                        {capitalize(cat.label)}
                      </Tag>
                    ))}
                </>
              )}
            </nav>
            <ResponsiveText
              as="h1"
              className="categoryTitle"
              type="display"
              bold
              size="medium"
            >
              {cat && cat.title
                ? capitalize(cat.title)
                : 'Tous nos mangas en ligne'}
            </ResponsiveText>
            <ResponsiveText
              as="h2"
              type="display"
              size="small"
              className="categorySubtitle"
            >
              {cat && cat.subtitle
                ? cat.subtitle
                : 'Retrouve la liste des mangas inclus dans notre abonnement'}
            </ResponsiveText>
            <ResponsiveText
              as="p"
              type="text"
              size="medium"
              className="categoryShortDescription"
            >
              {cat && cat.shortDescription
                ? cat.shortDescription
                : 'Lis tes mangas en ligne légalement grâce à un système à l’abonnement et découvre des milliers de chapitres en lecture illimitée. Plus la plateforme a de succès, plus nous ajoutons de nouveaux titres au catalogue ! Découvre dès maintenant de nouvelles pépites ! 🔥'}
            </ResponsiveText>
          </div>

          {!loading && (
            <MangaSearch filters={cat ? `categories:"${cat.label}"` : ''} />
          )}
        </div>
      </Page>
      <div className="LibraryPage-infos MangaInfo">
        <div className="textDescription">
          <ResponsiveText
            as="h2"
            type="display"
            size="small"
            bold
            className="subtitleSecondary"
          >
            {cat && cat.subtitleSecondary
              ? cat.subtitleSecondary
              : 'Pourquoi lire un manga en ligne sur Mangas.io ?'}
          </ResponsiveText>
          <ResponsiveText as="p" type="text" size="medium">
            {cat && cat.description
              ? cat.description
              : `Tu cherches le meilleur site légal pour lire un manga en ligne en vf ? Bienvenue sur Mangas.io, la première application de lecture 100% manga qui fonctionne à l’abonnement !
Pour le prix d’un manga par mois, tu accèdes à un large catalogue incluant de nombreuses BD japonaises dont la majorité sont disponibles exclusivement à l’abonnement sur Mangas.io.
Sur notre plateforme, tu pourras retrouver tes séries préférés en HD avec les traductions officielles et le tout, sans pub !
En plus de rémunérer tes mangakas favoris, tu pourras découvrir de nouvelles pépites parfois disponibles en avant-première ou seulement chez nous.`}
          </ResponsiveText>
        </div>
      </div>
      <PreFooterTrial />
      <Footer />
    </>
  );
}

function PublicLibraryRouter() {
  const match = useRouteMatch();
  return (
    <Route exact path={[match.path, `${match.path}/:category`]}>
      <PublicLibrary />
    </Route>
  );
}

export default PublicLibraryRouter;
