import React from 'react';
import classnames from 'classnames';
import BackLink from 'UI/BackLink/BackLink';
import { Button } from 'design-system';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import { useResponsive } from 'hooks';
import './OnboardingBackground.scss';

export default function OnboardingBackground({
  children,
  className,
  isSuccess,
  ...props
}) {
  const { is } = useResponsive();
  const isDesktop = is('lg');
  return (
    <div className={classnames('onboardingBackground', className)} {...props}>
      <div className={isSuccess ? 'successBg' : 'backgroundImage'}>
        <div className="blur" />
      </div>
      {!isSuccess && (
        <div className="back-button">
          <BackLink
            fallback="/"
            render={() => (
              <Button
                variant={isDesktop ? 'secondary' : 'text'}
                icon={<Icon path={mdiArrowLeft} />}
                label={isDesktop && 'Retour'}
                className="BackButton"
                horizontalLg
              />
            )}
          />
        </div>
      )}
      <div className="wrapper">{children}</div>
    </div>
  );
}
