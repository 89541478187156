import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Switch, MemoryRouter } from 'react-router-dom';

import { Input, Button, Text } from 'design-system';
import { Link } from 'react-router-dom';

import {
  CaptiveNavbar,
  LandingFaq,
  LandingFeatures,
  PublishersBloc,
  LandingUsersFeedback,
  Success,
  LandingHeroPartners,
  PopularConnected,
  DisclaimerInvited,
} from 'Components';

import { PublicRoute, trackHandler } from 'utils';
import {
  useAuth,
  useAppState,
  useProfile,
  BrowserHistoryProvider,
  useBrowserHistory,
} from 'hooks';

import { OnboardingBackground } from 'UI';

import '../Landing/LandingPage.scss';
import './RedeemPage.scss';

const errors = {
  'Invalid code': 'Code invalide',
  'Code limit reached': 'Code déjà utilisé',
  INVALID_STATUS_SEQUENCE: "Impossible d'activer ce code sur votre compte",
  default: 'Une erreur est survenue',
};

function getErrorMessage(error) {
  if (errors[error]) return errors[error];
  return errors.default;
}

function RedeemPageMcdo({ code = '' }) {
  const { isAuthenticated, validateInvite, addInvite } = useAuth();
  const [currentCode, setCode] = useState(code);
  const [disabled, setDisabled] = useState(currentCode.length === 0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [, actions] = useAppState();
  const history = useHistory();
  const browserHistory = useBrowserHistory();
  const [isPayingUser, setIsPayingUser] = useState(false);
  const { profile, loading: profileLoading, refreshProfile } = useProfile({
    subscription: true,
  });

  useEffect(() => {
    if (profile && profile.info) {
      if (!['visitor', 'invited', 'voucher'].includes(profile.info.status)) {
        setIsPayingUser(true);
        setDisabled(true);
      }
    }
  }, [profileLoading, profile]);

  useEffect(() => {
    setDisabled(!isPayingUser && currentCode.length === 0);
  }, [currentCode, isPayingUser]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (!isAuthenticated) {
        const { error, valid, code } = await validateInvite(currentCode);
        setLoading(false);
        if (error) setError(getErrorMessage(error));
        if (valid) {
          actions.setVoucherAction(currentCode);
          browserHistory.push('/signup');
        } else setError(getErrorMessage(code));
      } else {
        const { error, valid, code } = await addInvite(currentCode);
        setLoading(false);
        if (error) setError(getErrorMessage(error));
        if (valid) {
          actions.setVoucherAction(null);
          await refreshProfile();
          history.push('/success');
        } else setError(getErrorMessage(code));
      }
    } catch (e) {}
  };

  if (profileLoading) return null;

  return (
    <div id="Landing" className="RedeemPage burgerCursor">
      <CaptiveNavbar
        cta={
          <Button
            variant="primary"
            label="Activer l’offre"
            loading={loading}
            disabled={disabled}
            onClick={!disabled ? handleSubmit : undefined}
          />
        }
      />
      <header>
        <LandingHeroPartners
          tags={['Opération exceptionnelle !']}
          title="Découvre Mangas.io avec le Jeu Monopoly chez McDonald’s™ !"
          mobileImage="https://cdn.mangas.io/images/logo-mcdo-white.png"
          image="https://cdn.mangas.io/images/logo-mcdo-white.png"
          subtitle={
            isPayingUser ? (
              <>
                Tu es déjà abonné à Mangas.io. Si tu souhaites prolonger ton
                abonnement à l’aide d’une carte cadeau,{' '}
                <a
                  href="mailto:contact@mangas.io"
                  onClick={e =>
                    trackHandler(e, 'Contact Clicked', { type: 'contact' })
                  }
                >
                  contacte-nous par email
                </a>{' '}
                en indiquant ton code.
              </>
            ) : (
              <p className="hero-subtitle" data-aos="fade" data-aos-delay="300">
                Bravo ! Tu bénéficies{' '}
                <span style={{ color: '#D2FAEA', fontWeight: 'bold' }}>
                  d’un mois d’abonnement offert sur Mangas.io
                </span>{' '}
                pour découvrir une sélection de mangas{' '}
                <span style={{ color: '#D2FAEA', fontWeight: 'bold' }}>
                  à lire à volonté
                </span>{' '}
                !
              </p>
            )
          }
        >
          <div className="code-validation-input">
            <Input
              value={currentCode}
              onChange={e => setCode(e.target.value)}
              placeholder="Mon code partenaire"
              highlight={error ? 'error' : null}
              helper={error}
            />
            <Button
              label="Activer"
              variant="primary"
              size="large"
              loading={loading}
              disabled={disabled}
              onClick={!disabled ? handleSubmit : undefined}
            />
          </div>
          <DisclaimerInvited profile={profile} />
        </LandingHeroPartners>
      </header>

      <section id="advantage" className="section">
        <PublishersBloc />
      </section>

      <section id="moreMangas">
        <PopularConnected title="Des milliers de mangas à découvrir" />
        <Link className="buttonMangas" to={`/manga-en-ligne`}>
          <Button
            id="button"
            size="large"
            variant="primary"
            label="Voir tout le catalogue"
          />
        </Link>
      </section>

      <section id="faq">
        <LandingFaq
          title="Tout savoir sur le Jeu Monopoly chez McDonald’s™ x Mangas.io"
          page_name="mcdo"
        />
      </section>

      <section id="features" className="section">
        <LandingFeatures />
      </section>

      <section id="feedback" className="section">
        <LandingUsersFeedback />
      </section>
      <section id="copyrights" className="section">
        <Text size="small">Jeu Monopoly chez McDonald’s :</Text>
        <Text size="small">
          Jeu avec obligation d’achat. Voir dates, détails des dotations et
          modalités de participation dans le règlement complet du jeu sur{' '}
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="http://www.mcdonalds.fr"
            style={{ textDecoration: 'underline' }}
          >
            www.mcdonalds.fr
          </a>
          .
        </Text>
        <Text size="small">Ultimate edition = édition ultime.</Text>
        <Text size="small">MONOPOLY © 2023 Hasbro.</Text>
      </section>
    </div>
  );
}

function RedeemSuccess({ onClick }) {
  const history = useBrowserHistory();
  // TODO : event tracking ?
  return (
    <OnboardingBackground id="mio-loginPage">
      <Success>
        <Text bold>Félicitations !</Text>
        <Text>Ton code a bien été activé. Bonne lecture sur Mangas.io !</Text>
        <Button
          fullWidth
          className="divider"
          variant="primary"
          label="Continuer"
          onClick={() => history.push('/')}
        />
      </Success>
    </OnboardingBackground>
  );
}

/* eslint-disable no-unused-vars */
function RedeemPageMcdoRouter() {
  const { code } = useParams();
  return (
    <BrowserHistoryProvider>
      <MemoryRouter>
        <Switch>
          <PublicRoute path="/success" component={<RedeemSuccess />} />
          <PublicRoute path="/" component={<RedeemPageMcdo code={code} />} />
        </Switch>
      </MemoryRouter>
    </BrowserHistoryProvider>
  );
}
/* eslint-disable no-unused-vars */

export default function RedeemPageMcdoDisabled() {
  // export function RedeemPageMcdoRouter to enable this page again
  return null;
}
