import React from 'react';

import { FloatingMenu, ResponsiveImage } from 'UI';
import { IndicatorPill, Text } from 'design-system';
import { repriseFormatter, dayjs } from 'utils';
import classnames from 'classnames';

import ContinueReadingBlock from './ContinueReadingBlock';
import './ContinueReading.scss';

const ContinueReading = props => {
  const { history, loading } = props;
  const [currentTab, setCurrentTab] = React.useState('ongoing');
  if (!history) return null;
  const formattedHistory = repriseFormatter(history);

  const data = {
    ongoing: formattedHistory.filter(
      h => !h.chapterIsFinished && dayjs(h.chapter.releaseDate).isBefore()
    ),
    upcoming: formattedHistory.filter(h =>
      dayjs(h.chapter.releaseDate).isAfter()
    ),
    completed: formattedHistory.filter(
      h => h.chapterIsFinished && !h.hasNextChapter
    ),
  };

  const newChapters = data.ongoing.filter(h =>
    dayjs(h.chapter.releaseDate).isAfter(dayjs().subtract(4, 'day'))
  );

  const NoHistory = () => {
    if (currentTab === 'ongoing' && data.ongoing.length === 0)
      return (
        <div className="noHistory">
          <ResponsiveImage
            width="100"
            src="https://cdn.mangas.io/images/coffee/reading.png"
          />
          <Text className="noHistory_title">Tu débutes avec Mangas.io ?</Text>
          <Text size="small">
            Commence à lire un manga et retrouve l'historique de tes lectures en
            cours juste ici !
          </Text>
        </div>
      );

    if (currentTab === 'upcoming' && data.upcoming.length === 0)
      return (
        <div className="noHistory">
          <ResponsiveImage
            width="100"
            src="https://cdn.mangas.io/images/coffee/reading.png"
          />
          <Text className="noHistory_title">
            Pas de nouveaux chapitres de tes lectures en cours prévus
            prochainement...
          </Text>
          <Text size="small">Pourquoi ne pas entamer un nouveau manga ?</Text>
        </div>
      );

    if (currentTab === 'completed' && data.completed.length === 0)
      return (
        <div className="noHistory">
          <ResponsiveImage
            width="100"
            src="https://cdn.mangas.io/images/coffee/reading.png"
          />
          <Text className="noHistory_title">Nannniiii ?!</Text>
          <Text size="small">
            Tu n'as pas encore lu de mangas en intégralité sur Mangas.io ? Fonce
            !
          </Text>
        </div>
      );
    else return null;
  };
  return (
    <>
      <FloatingMenu.Header className="menuSelector">
        Reprendre la lecture
        <div className="tabsSelector">
          <div
            className={classnames('tab', {
              selectedTab: currentTab === 'ongoing',
            })}
            onClick={() => setCurrentTab('ongoing')}
          >
            <Text size="small">En cours</Text>
            {newChapters.length > 0 && (
              <IndicatorPill className="pill">
                {newChapters.length}
              </IndicatorPill>
            )}
          </div>
          <div
            className={classnames('tab', {
              selectedTab: currentTab === 'upcoming',
            })}
            onClick={() => setCurrentTab('upcoming')}
          >
            <Text size="small">À venir</Text>
          </div>
          <div
            className={classnames('tab', {
              selectedTab: currentTab === 'completed',
            })}
            onClick={() => setCurrentTab('completed')}
          >
            <Text size="small">Terminées</Text>
          </div>
        </div>
      </FloatingMenu.Header>
      <FloatingMenu.Body>
        <div className="ContinueReading floating-container">
          {loading && (
            <div className="rows">
              {[1, 2, 3].map(key => {
                return <ContinueReadingBlock key={key} loading />;
              })}
            </div>
          )}
          {history && (
            <>
              <NoHistory />
              {data[currentTab].map(history => (
                <ContinueReadingBlock
                  isCompleted={currentTab === 'completed'}
                  key={history.manga._id}
                  history={history}
                  refetch={props.refetch}
                  withCornerButtons
                />
              ))}
            </>
          )}
        </div>
      </FloatingMenu.Body>
    </>
  );
};

ContinueReading.defaultProps = {
  history: [],
};

export default ContinueReading;
