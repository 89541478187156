import React from 'react';
import { Disclaimer } from 'design-system';

import './LandingGiftcard.scss';

const LandingGiftcard = props => {
  return (
    <div id="LandingGiftcard" className="">
      <div className="giftcard-container">
        <div className="giftcard-wrapper">
          <p className="giftcard-collector-tag">
            <img
              className="icon"
              src="https://cdn.mangas.io/images/mdi_gift_gold.svg"
              width="24"
              height="24"
              alt="Icon Gift in Gold"
            />
            <span className="text-gold">édition limitée</span>
          </p>
          <h2 className="giftcard-title">{props.title}</h2>
          <p className="giftcard-subtitle">
            Pour l’achat du pack collector, reçois nos 3 cartes en édition
            limitée du manga <i>Tales of Demons and Gods</i> contenant chacune 1
            mois d’abonnement.
          </p>
          <div className="giftcard-alert-wrapper">
            <Disclaimer
              type="hint"
              text="Les 3 cartes peuvent être utilisées ensemble ou séparément afin de
              les offrir à qui tu veux"
            />
          </div>
          {props.children && (
            <div className="giftcard-cta">
              <div>{props.children}</div>
            </div>
          )}
        </div>
        <div className="giftcard-card-wrapper">
          <div className="giftcard-display">
            <div className="giftcard-container mio-scrollbar">
              <div className="card">
                <img
                  className="card"
                  src="https://cdn.mangas.io/images/voucher/collector-card-1.png"
                  width="250"
                  height="394"
                  alt="Carte cadeau collector Xiao Ning"
                />
              </div>
              <div className="card">
                <img
                  className="card"
                  src="https://cdn.mangas.io/images/voucher/collector-card-2.png"
                  width="250"
                  height="394"
                  alt="Carte cadeau collector Ye Ziyun"
                />
              </div>
              <div className="card">
                <img
                  className="card"
                  src="https://cdn.mangas.io/images/voucher/collector-card-3.png"
                  width="250"
                  height="394"
                  alt="Carte cadeau collector Nie-li"
                />
              </div>
              <div className="card">
                <img
                  className="card"
                  src="https://cdn.mangas.io/images/voucher/collector-card-back.png"
                  width="250"
                  height="394"
                  alt="Carte cadeau collector arrière"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingGiftcard;
