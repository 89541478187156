import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './RadioButton.scss';

const RadioButton = ({ onClick, children, className, ...props }) => (
  <button
    className={classnames('mio-radio-button', className)}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);

RadioButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.element,
  className: PropTypes.string,
};

RadioButton.defaultProps = {};

export default RadioButton;
