import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './articleCard.scss';

const ArticleCard = ({ picture, title, date, ...props }) => {
  return (
    <div className={classnames('mio-articlecard')} {...props}>
      <div className={'mio-articlecard-imgBlock'}>
        <div style={{ backgroundImage: `url(${picture})` }}></div>
      </div>
      <div className={'mio-articlecard-textBlock'}>
        <span className={'mio-articlecard-title'}>{title}</span>
        <span className="mio-articlecard-date">{date}</span>
      </div>
    </div>
  );
};

ArticleCard.propTypes = {
  //picture of my card
  picture: PropTypes.string.isRequired,

  //title of card
  title: PropTypes.string.isRequired,

  //number of chapter
  date: PropTypes.string.isRequired,
};

export default ArticleCard;
