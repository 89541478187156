import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './text.scss';

/**
 * Primary UI component for user interaction
 */
const Text = ({ bold, type, size, mobile, as: Tag, className, ...props }) => {
  return (
    <Tag
      className={classnames(
        'mio-text',
        { bold, mobile },
        { [size]: true, [type]: true },
        className
      )}
      {...props}
    ></Tag>
  );
};

Text.propTypes = {
  /**
   * Is this text bold?
   */
  bold: PropTypes.bool,
  /**
   * Which type will it be?
   */
  type: PropTypes.oneOf(['display', 'text']),
  /**
   * How large should the text be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large', 'extra-large']),
  /**
   * Is this text for mobile?
   */
  mobile: PropTypes.bool,
  /**
   * Custom html tag to render (h1, p, span...)
   */
  as: PropTypes.string,
};

Text.defaultProps = {
  bold: false,
  mobile: false,
  type: 'text',
  size: 'medium',
  as: 'p',
};

export default Text;
