import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { CtaImage, Bloc, Disclaimer, Button } from 'design-system';
import Icon from '@mdi/react';
import { mdiSortAscending, mdiSortDescending, mdiClose } from '@mdi/js';
import { useQuery, useResponsive, useAuth, useBundles, useFlags } from 'hooks';
import {
  flatMap,
  dayjs,
  pluralize,
  trackLink,
  getResizedUrl,
  withPixelDensity,
  track,
} from 'utils';
import {
  Affiliation,
  ContinueReadingBlockConnected,
  Footer,
  LinkedContentConnected,
  MangaInfo,
  NotFound,
  NudgeColumn,
  MangaHeader,
  SEO,
  Trailer,
  VolumeList,
  SimilarMangas,
  NudgeBanner,
} from 'Components';
import { ResponsiveColumns, Mask, Page, StatusSwitch } from 'UI';
import './MangaPage.scss';

const PUBLISHER_FRAGMENT = `
  publisher {
    _id
    slug
    name
    countryCode
    logo {
      url
    }
  }
  releaseDate
`;

const VOLUME_FRAGMENT = `
  _id
  title
  ean13
  label
  description
  number
  publicationDate
  releaseDate
  thumbnail {
    url
    pos_x
    pos_y
  }
  chapterStart
  chapterEnd
  chapters {
    _id
    number
    title
    isRead
    isBonus
    isSeparator
    access
    publicationDate
    releaseDate
    pageCount
    bundles
  }
`;

const GQL_GET_MANGA = gql`
  query GetManga($slug: String) {
    manga(slug: $slug) {
      _id
      slug
      title
      subtitle
      description
      releaseDate
      age
      trailer
      isOngoing
      alternativeTitles
      chapterCount
      ctas {
        url
        image {
          url
        }
      }
      bannerMobile: banner(target: MOBILE) {
        url
      }
      banner {
        url
      }
      categories {
        label
        slug
        isFavorite
        level
        _id
      }
      authors {
        _id
        slug
        name
      }
      thumbnail {
        url
      }
      publishers {
        ${PUBLISHER_FRAGMENT}
      }
      volumes {
        ${VOLUME_FRAGMENT}
      }
    }
  }
`;

function ChapterBlocPreHeader({ mobile, volumes = [] }) {
  const limitDate = dayjs().subtract(4, 'day');
  const today = dayjs();

  const recentUnreadChapters = flatMap(volumes, volume => volume.chapters)
    .filter(
      chapter =>
        !chapter.isRead &&
        dayjs(chapter.releaseDate).isAfter(limitDate) &&
        dayjs(chapter.releaseDate).isBefore(today)
    )
    .sort((a, b) =>
      dayjs(a.publicationDate).isBefore(b.publicationDate) ? -1 : 1
    )
    .shift();

  if (!recentUnreadChapters) return null;

  return (
    <div className="pre-header-action">
      <div className="pre-header-component">
        <div>
          <div className="pre-header-title">Nouveau chapitre</div>
        </div>
      </div>

      <div>
        <div className="pre-header-timing">
          {dayjs(recentUnreadChapters.releaseDate).fromNow()}
        </div>
      </div>
    </div>
  );
}

function reverseVolumes(volumes) {
  return [...volumes].reverse().map(volume => {
    return {
      ...volume,
      chapters: [...volume.chapters].reverse(),
    };
  });
}

export default function MangaPage() {
  const { bundleAccess } = useBundles();
  const { slug } = useParams();
  const { isAuthenticated } = useAuth();
  const { data, loading } = useQuery(GQL_GET_MANGA, {
    variables: { slug },
  });
  const { is } = useResponsive();
  const isDesktop = is('lg');

  const [sortAscending, setSortAscending] = useState(true);
  const { manga } = data || {};
  const { volumes = [] } = manga || {};

  const [displayTrailerModal, setTrailerModal] = useState(false);
  const { flag: noAffiliationFlag } = useFlags('NO_AFFILIATION');
  const { flag: noPromoFlag } = useFlags('NO_PROMO');
  const toggleTrailerModal = () => setTrailerModal(!displayTrailerModal);

  useEffect(() => {
    if (manga?.categories.some(cat => cat.slug === 'simultrad'))
      setSortAscending(false);
  }, [manga]);

  useEffect(() => {
    if (manga?._id)
      track('Manga Viewed', {
        manga_id: manga._id,
        title: manga.title,
        slug: manga.slug,
        description: manga.description,
        thumbnail: manga.thumbnail?.url,
        categories: manga.categories?.map(category => category.label),
        authors: manga.authors?.map(author => author.name),
        publishers: manga.publishers?.map(
          publisher => publisher.publisher?.name
        ),
        volumes: manga.volumes?.length,
      });
  }, [manga]);

  if (!manga && !loading) return <NotFound />;
  if (loading) return null;

  const hasBundleRestrictions = manga.volumes.some(volume =>
    volume.chapters.some(chapter => bundleAccess(chapter.bundles) === 'LIST')
  );

  const today = dayjs();

  function RightTitle() {
    return (
      <>
        <Button
          id="btn-order"
          variant="text"
          onClick={() => setSortAscending(!sortAscending)}
          label={`Ordre ${sortAscending ? 'croissant' : 'décroissant'}`}
          icon={
            <Icon
              path={sortAscending ? mdiSortAscending : mdiSortDescending}
              width="20px"
            />
          }
        />
      </>
    );
  }

  function Nudge(props) {
    return (
      <div className="disclaimerProfile">
        <NudgeBanner {...props} />
      </div>
    );
  }

  return (
    <>
      <Page id="MangaPage">
        <SEO
          title={`Lire ${manga.title} en ligne - Scan officiel et légal`}
          description={`Lis le manga ${manga.title} en ligne sur Mangas.io : ${manga.description}`}
          image={manga.bannerMobile || manga.banner}
          url={`${window.location.origin}/lire/${manga.slug}`}
        />

        <MangaHeader manga={manga} />
        {hasBundleRestrictions && (
          <StatusSwitch>
            <Nudge status="GUEST|FREE|INVITED" />
          </StatusSwitch>
        )}

        {displayTrailerModal && isDesktop && (
          <Mask
            className="mask"
            onClose={() => setTrailerModal(false)}
            withOverlay
          >
            <div className="trailer-modal">
              <div className="bloc-trailer">
                <Icon
                  className="icon-trailer-modal"
                  onClick={() => setTrailerModal(false)}
                  path={mdiClose}
                />
                <Trailer
                  loading={loading}
                  playing={true}
                  width={'100%'}
                  height={'80vh'}
                  light={false}
                  trailer={manga.trailer}
                />
              </div>
            </div>
          </Mask>
        )}

        <ResponsiveColumns oneColumn={!isDesktop}>
          {manga.releaseDate && dayjs(manga.releaseDate).isAfter(today) ? (
            <div renderPosition="main" id="disclaimer">
              <Disclaimer
                type="danger"
                size="large"
                title="Page visible uniquement par les comptes ADMIN"
              />
            </div>
          ) : null}

          {/* REPRISE DE LECTURE */}
          {isAuthenticated && !loading && (
            <ContinueReadingBlockConnected
              renderPosition="sidebar"
              manga={manga}
              wrapperElement={props => (
                <Bloc
                  className="continue-reading-bloc"
                  title="Continuer à lire"
                  {...props}
                />
              )}
            />
          )}

          {/* MANGA INFO */}
          <Bloc
            className="information-bloc"
            renderPosition="main"
            title={`Tout savoir sur ${manga.title}`}
          >
            <MangaInfo manga={manga} />
          </Bloc>

          {/* LISTE DES CHAPITRES */}
          <Bloc
            className="no-padding chapters-bloc"
            pre_header={<ChapterBlocPreHeader volumes={volumes} />}
            renderPosition="main"
            title_right={<RightTitle />}
            subtitle={
              manga.chapterCount
                ? ` ${volumes.length} ${pluralize(
                    'Tome',
                    'Tomes',
                    volumes.length
                  )} - ${manga.chapterCount} ${pluralize(
                    'Chapitre',
                    'Chapitres',
                    manga.chapterCount
                  )}`
                : null
            }
            title="Chapitres"
          >
            <VolumeList
              volumes={sortAscending ? volumes : reverseVolumes(volumes)}
              slug={slug}
            />
          </Bloc>

          {/* TRAILER */}
          {!noPromoFlag && (
            <Trailer
              renderPosition="sidebar"
              loading={loading}
              playing={isDesktop ? true : false}
              width={'100%'}
              height={'100%'}
              light={isDesktop ? true : false}
              trailer={manga.trailer}
              wrapperElement={props => (
                <Bloc
                  className="trailer-bloc"
                  title="Trailer"
                  {...props}
                  onClick={toggleTrailerModal}
                />
              )}
            />
          )}

          {/* CTA (FROM ADMIN) */}
          {!noAffiliationFlag && !noPromoFlag && manga.ctas && (
            <div className="ctaContainer" renderPosition="sidebar">
              {manga.ctas.map((cta, k) => (
                <CtaImage
                  className="ctaImageSidebar"
                  key={k}
                  link={cta.url}
                  imageLink={getResizedUrl(cta.image.url, {
                    width: withPixelDensity(360),
                  })}
                />
              ))}
            </div>
          )}

          {/* NUDGE  */}
          <NudgeColumn renderPosition="sidebar" />

          {/* CONTENUS LIÉS  */}
          {!noPromoFlag && (
            <LinkedContentConnected
              search={manga.title}
              renderPosition="sidebar"
              wrapperElement={props => (
                <Bloc
                  className="continue-lies-bloc"
                  title="Contenus liés au titre"
                  {...props}
                />
              )}
            />
          )}

          {/* AFFILIATION */}
          {!noPromoFlag && (
            <Affiliation
              renderPosition="sidebar"
              manga={manga}
              volume={volumes}
              tomevolume={volumes.number}
              wrapperElement={props => (
                <Bloc
                  className="affiliation-bloc"
                  title="Acheter les volumes"
                  {...props}
                />
              )}
            />
          )}

          {!noPromoFlag && (
            <CtaImage
              className="discord-img"
              link={trackLink('https://discord.gg/T3FAWnw', 'Discord Clicked')}
              imageLink={getResizedUrl(
                'https://cdn.mangas.io/storybook/Discord/join.png',
                {
                  width: withPixelDensity(360),
                }
              )}
              altText="Rejoins-nous sur Discord!"
              renderPosition="sidebar"
              target="_blank"
              rel="noreferrer noopener"
            />
          )}

          {/* MANGAS SIMILAIRES */}
          <SimilarMangas manga={manga} renderPosition="main" />
        </ResponsiveColumns>
      </Page>

      <Footer />
    </>
  );
}
