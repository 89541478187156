import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './progressBar.scss';

const ProgressBar = ({ value, direction, ...props }) => {
  return (
    <div className={'mio-progressbar'} {...props}>
      <div
        className={classnames('mio-progressbar-level', { [direction]: true })}
        style={{ width: `${value}%` }}
      ></div>
    </div>
  );
};

ProgressBar.propTypes = {
  // size of the progressbar
  value: PropTypes.number.isRequired,

  //Direction (left to right or right to left)
  direction: PropTypes.oneOf(['rtl', 'ltr']),
};

ProgressBar.defaultProps = {
  direction: 'ltr',
};

export default ProgressBar;
