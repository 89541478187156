import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './pill.scss';

const Pill = ({ size, backgroundColor, style, className, ...props }) => {
  return (
    <span
      className={classnames('mio-pill', { [size]: true }, className)}
      {...props}
      style={{ ...style, backgroundColor: backgroundColor }}
    >
      {props.children}
    </span>
  );
};

Pill.propTypes = {
  // get the color of my pill
  backgroundColor: PropTypes.string.isRequired,

  // How large should the pill be?
  size: PropTypes.oneOf(['medium', 'large']),

  style: PropTypes.object,

  children: PropTypes.element,
};

Pill.defaultProps = {
  size: 'medium',
};

export default Pill;
