import React from 'react';
import classnames from 'classnames';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiLightbulbOn, mdiBugOutline } from '@mdi/js';
import { Text } from 'design-system';

import { FloatingMenu } from 'UI';
import { useProfile } from 'hooks';

import { trackHandler, upvoty } from 'utils';

const HelpSlide = props => {
  const { navigation, flags } = props;
  const { profile } = useProfile();

  return (
    <div className={classnames(props.className, 'slide', 'help-slide')}>
      <FloatingMenu.Header
        className="clickable"
        onClick={() => navigation.pop()}
      >
        <span className="back-icon">
          <Icon path={mdiArrowLeft} />
        </span>
        Aide
      </FloatingMenu.Header>
      <FloatingMenu.Body>
        {!flags.noPromo && (
          <a
            className="row clickable"
            target="_blank"
            rel="noopener noreferrer"
            href="https://mangasio.upvoty.com/b/demande-de-fonctionnalite/"
            onClick={e => {
              upvoty.upvotySSO(profile);
              trackHandler(e, 'Feature Request Clicked');
            }}
          >
            <span className="settings-icon">
              <Icon path={mdiLightbulbOn} />
            </span>

            <Text>Proposer une fonctionnalité</Text>
          </a>
        )}
        {!flags.noPromo && (
          <a
            className="row clickable"
            target="_blank"
            rel="noopener noreferrer"
            href="https://mangasio.upvoty.com/b/corrections-et-bugs/"
            onClick={e => {
              upvoty.upvotySSO(profile);
              trackHandler(e, 'Bug Report Clicked');
            }}
          >
            <span className="settings-icon">
              <Icon path={mdiBugOutline} />
            </span>

            <Text>Signaler un bug ou une correction</Text>
          </a>
        )}
      </FloatingMenu.Body>
    </div>
  );
};

export default HelpSlide;
