import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './avatar.scss';

//Display avatar pic
const Avatar = ({ picture, notification, premium, ...props }) => {
  return (
    <div
      className={classnames('mio-avatar', { notification, premium })}
      {...props}
    >
      <img alt="avatar" src={picture}></img>
    </div>
  );
};

Avatar.propTypes = {
  //Avatar image
  picture: PropTypes.string.isRequired,
  //has notification
  notification: PropTypes.bool,
  //Has premium
  premium: PropTypes.bool,
};

Avatar.defaultProps = {
  notification: false,
  premium: false,
};

export default Avatar;
