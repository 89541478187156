import { CustomErrorBoundary } from 'utils';
import { Bloc } from 'design-system';
import SimilarMangasLine from './SimilarMangasLine';

function SimilarMangas({ manga }) {
  if (manga.length === 0) return null;
  return (
    <Bloc
      className="similar-bloc"
      title={`Découvre nos mangas similaires à ${manga.title}`}
      subtitle="Si tu as aimé ce titre, tu adoreras..."
    >
      <SimilarMangasLine manga={manga} />
    </Bloc>
  );
}
export default function SimilarMangasShielded(props) {
  return (
    <CustomErrorBoundary>
      <SimilarMangas {...props} />
    </CustomErrorBoundary>
  );
}
