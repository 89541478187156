import React from 'react';
import classnames from 'classnames';
import { Text } from 'design-system';
import { useResponsive } from 'hooks';

function ResponsiveText({ breakpoint = 'md', className, ...props }) {
  const { is } = useResponsive();
  const isBiggerThanBreakpoint = is(breakpoint);
  return (
    <Text
      className={classnames(
        {
          mobile: !isBiggerThanBreakpoint,
        },
        className
      )}
      {...props}
    />
  );
}

export default ResponsiveText;
