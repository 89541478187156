import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@mdi/react';
import { mdiChevronRight, mdiInformationOutline } from '@mdi/js';
import './volumecard.scss';

import Text from '../Text/Text';

const VolumeCard = ({
  open,
  size,
  backgroundImage,
  backgroundPosition,
  cover,
  title,
  subtitle,
  description,
  action,
  className,
  ...props
}) => {
  return (
    <div
      className={classnames(
        'mio-volume-card',
        { [size]: true }, // value props
        className // On garde toutes les classes passées en props
      )}
      {...props}
    >
      <div className="mask">
        <div className="toggle">
          <Icon
            path={mdiChevronRight}
            className={classnames({ rotate90: open })}
          />
        </div>

        <div
          className={classnames('cover', { smallerCover: size === 'small' })}
          style={{
            backgroundImage: `url(${cover})`,
          }}
        />

        <div className="content">
          <Text as="h3" className="title">
            {title}
          </Text>
          <Text className="subtitle">{subtitle}</Text>
          {description && size === 'large' && (
            <Text className="description" size="small">
              {description}
            </Text>
          )}
        </div>
        {action && (
          <div className="action">
            <Icon path={mdiInformationOutline} onClick={action} />
          </div>
        )}
      </div>
    </div>
  );
};

VolumeCard.propTypes = {
  backgroundImage: PropTypes.string,
  backgroundPosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  cover: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
  action: PropTypes.func,
  open: PropTypes.bool,
};

VolumeCard.defaultProps = {
  size: 'large',
  backgroundPosition: { x: 50, y: 50 },
  open: false,
};

export default VolumeCard;
