import React, { useState } from 'react';
import classnames from 'classnames';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import { Text, Input, Button } from 'design-system';
import { DisclaimerInvited } from 'Components';
import { FloatingMenu } from 'UI';
import { useAuth, useProfile } from 'hooks';

import './VoucherCodeSlide.scss';

const ERRORS = {
  'Invalid code': 'Code invalide',
  'NOT FOUND': 'Code invalide',
  GONE: 'Code déjà utilisé',
  default: 'Une erreur est survenue',
};

function getErrorMessage(error) {
  if (ERRORS[error]) return ERRORS[error];
  return ERRORS.default;
}

export default function VoucherCodeSlide(props) {
  const { className, navigation } = props;
  const { addInvite } = useAuth();
  const { refreshProfile, profile } = useProfile();
  const [code, setCode] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  function handleCode(e) {
    setCode(e.target.value);
  }
  async function handleSubmit(e) {
    setLoading(true);
    const result = await addInvite(code);
    await refreshProfile();
    setLoading(false);
    if (!result.valid) return setError(getErrorMessage(result.error));
    setError(null);
    navigation.push('voucher_success');
  }

  return (
    <div className={classnames(className, 'slide')}>
      <FloatingMenu.Header
        className="clickable"
        onClick={() => navigation.pop()}
      >
        <span className="back-icon">
          <Icon path={mdiArrowLeft} />
        </span>
        Carte cadeau
      </FloatingMenu.Header>
      <FloatingMenu.Body>
        <div className="voucher-code-slide">
          <Text>Saisis le code reçu avec ta carte cadeau pour l’activer :</Text>
          <Input
            onChange={handleCode}
            label="Code"
            highlight={error && 'error'}
            helper={error}
            placeholder="Rentre ton code ici..."
          />
          <Button
            onClick={handleSubmit}
            label="Activer ma carte cadeau"
            variant="primary"
            loading={loading}
            fullWidth
          />
          <DisclaimerInvited profile={profile} />
        </div>
      </FloatingMenu.Body>
    </div>
  );
}
