import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './tag.scss';

const Tag = ({ className, size, ...props }) => {
  return (
    <span
      className={classnames('mio-tag', className, { [size]: true })}
      {...props}
    />
  );
};

Tag.propTypes = {
  size: PropTypes.oneOf(['small', 'medium']),
};

Tag.defaultProps = {
  size: 'medium',
};

export default Tag;
