import React from 'react';
import { useHistory } from 'react-router-dom';
import { Disclaimer, Button } from 'design-system';
import { useFeatureFlags, useResponsive, useFlags } from 'hooks';

import './FlashMessage.scss';

function FlashMessage({ render = v => v, location = 'home' }) {
  const flagName = `flash_message_${location}`;
  const flag = useFeatureFlags([flagName]);

  const { is } = useResponsive();
  const isMobile = !is('md');

  const history = useHistory();

  if (!flag[flagName].enabled || !flag[flagName].value) return null;

  try {
    const config = JSON.parse(flag[flagName].value);
    return render(
      <div className="flash-message">
        <Disclaimer
          type={config.type}
          title={config.title}
          text={config.message}
          size={isMobile ? 'small' : 'large'}
          button={
            config.button ? (
              <Button
                label={config.button}
                onClick={() => history.push(config.link)}
                variant="text"
              />
            ) : null
          }
        />
      </div>
    );
  } catch {
    return null;
  }
}

export default function FlashMessageWithPromoShield(props) {
  const { flag: disablePromo, loading } = useFlags('NO_PROMO', true);

  if (disablePromo || loading) return null;
  return <FlashMessage {...props} />;
}
