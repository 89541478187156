import React from 'react';
import { BackLink } from 'UI';
import { useResponsive } from 'hooks';
import { Text, Button } from 'design-system';
import '../../Pages/Error/ErrorPage.scss';
export default function NotFound(props) {
  const { is } = useResponsive();
  const isDesktop = !is('lg');
  let { error, code, message } = props;
  code = code || 404;
  error = error || 'Cette page n’est pas disponible';
  message =
    message ||
    'L’adresse que tu as recherchée n’existe pas ou n’existe plus. Essaye une autre adresse ou retourne à la page précédente.';
  return (
    <div id="errorPage">
      <div className="errorFlex">
        <div id="ErrorContainer">
          {isDesktop ? (
            <div className="cookie404 sm">
              <img
                alt="cookie error sm"
                src="https://cdn.mangas.io/landing/coffee-question-corps-x-min.png"
                width={100}
              />
            </div>
          ) : null}
          <Text className="marginBottom" type="display" size="large">
            {code} (T•T)
          </Text>
          <Text className="marginBottom" type="display" size="medium">
            {error}
          </Text>
          <Text className="marginBottom" type="mobile" size="large">
            {message}
          </Text>
          <div className="actions">
            <BackLink
              fallback="/"
              render={() => (
                <Button variant="primary" label="Accueil" className="backBtn" />
              )}
            />
          </div>
        </div>
        {!isDesktop ? (
          <div className="cookie404">
            <img
              alt="cookie error"
              src="https://cdn.mangas.io/landing/coffee-question-corps-x-min.png"
              width={404}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
