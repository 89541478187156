import React, { useState, useEffect } from 'react';
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share';
import { useResponsive } from 'hooks';

import Icon from '@mdi/react';
import { mdiShareVariant, mdiLink } from '@mdi/js';
import { Button, Text } from 'design-system';
import './ShareButton.scss';
import classnames from 'classnames';
import { track } from 'utils';

const ShareButton = props => {
  const { is } = useResponsive();
  const isDesktop = is('lg');
  const [displayShareLinks, setShareLinks] = useState(false);

  const shareUrl = window.location.href;
  const [copySuccess, setCopySuccess] = useState(false);
  const toggleShareLinks = () => {
    setShareLinks(!displayShareLinks);
    setCopySuccess(false);
  };
  useEffect(() => {}, [copySuccess]);

  async function copy() {
    await navigator.clipboard.writeText(shareUrl);
    setCopySuccess(true);
    track('Share Link Copied', {
      url: shareUrl,
    });
  }

  return (
    <div className="ShareButton">
      <Button
        variant={isDesktop ? 'secondary' : 'text'}
        label={isDesktop ? 'Partager' : null}
        icon={<Icon path={mdiShareVariant} />}
        size="large"
        onClick={toggleShareLinks}
        {...props}
      />

      {displayShareLinks && (
        <div className="share-links" onMouseLeave={() => setShareLinks(false)}>
          <div>
            <TwitterShareButton url={shareUrl} className="share-link-icon">
              <TwitterIcon size={32} round={true}></TwitterIcon>

              <Text className="label-share" type="text" size="small">
                Twitter
              </Text>
            </TwitterShareButton>
          </div>

          <div>
            <FacebookShareButton url={shareUrl} className="share-link-icon">
              <FacebookIcon size={32} round={true}></FacebookIcon>

              <Text className="label-share" type="text" size="small">
                Facebook
              </Text>
            </FacebookShareButton>
          </div>
          <div>
            <EmailShareButton url={shareUrl} className="share-link-icon">
              <EmailIcon size={32} round={true}></EmailIcon>

              <Text className="label-share" type="text" size="small">
                E-mail
              </Text>
            </EmailShareButton>
          </div>
          <div>
            <EmailShareButton className="share-link-icon" onClick={copy}>
              <div
                className={classnames('animation', { copied: copySuccess })}
              ></div>
              <Icon className="urlShare" path={mdiLink} />
              <Text className="label-share long-label" type="text" size="small">
                {copySuccess ? (
                  <div className="copiedLink">
                    Copié !
                    <img
                      src="https://cdn.mangas.io/landing/coffee-manga-min.png"
                      width="20"
                      height="20"
                      alt="coffee reading a book"
                    />
                  </div>
                ) : (
                  "Copier l'URL"
                )}
              </Text>
            </EmailShareButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareButton;
