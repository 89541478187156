import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiBookmarkOutline, mdiBookmark } from '@mdi/js';
import { Button } from 'design-system';

function FavoriteButton(props) {
  const {
    isFavorite,
    onAdd,
    onRemove,
    loading,
    noLabel,
    ...buttonProps
  } = props;
  return (
    <Button
      onClick={e => {
        e.preventDefault();
        if (loading) return;
        if (isFavorite) onRemove();
        else onAdd();
      }}
      size="large"
      icon={<Icon path={isFavorite ? mdiBookmark : mdiBookmarkOutline} />}
      label={noLabel ? null : 'Ma liste'}
      {...buttonProps}
    />
  );
}

FavoriteButton.propTypes = {
  isFavorite: PropTypes.bool,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  noLabel: PropTypes.bool,
};

export default FavoriteButton;
