import React, { useState } from 'react';
import classnames from 'classnames';
import { Button, Input, Text } from 'design-system';
import gql from 'graphql-tag';

import { FloatingMenu } from 'UI';
import { useMutation } from 'hooks';
import { GraphqlShield } from 'utils';

import './RemoveAccountSlide.scss';

const POST_REQUEST_DELETION = gql`
  mutation requestDeletion($password: String!) {
    requestDeletion(password: $password)
  }
`;

function getErrorMessage(errors) {
  if (!errors) return null;
  if (errors.includes('BAD_CREDENTIALS')) {
    return 'Mot de passe incorrect';
  } else if (errors.includes('ACTIVE_SUBSCRIPTION')) {
    return 'Tu as un abonnement en cours. Résilie-le avant de pouvoir supprimer le compte';
  } else {
    return 'Une erreur est survenue';
  }
}

const RemoveAccountSlide = props => {
  const { navigation, className, errors } = props;
  const [password, setPassword] = useState('');
  const [requestDeletion] = useMutation(POST_REQUEST_DELETION, {});

  const handleOnPress = async e => {
    e.preventDefault();
    try {
      await requestDeletion({
        variables: {
          password: password,
        },
      });
      setPassword('');
      navigation.push('remove_code');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div
      className={classnames(className, 'slide', 'cancel-subscription-slide')}
    >
      <FloatingMenu.Header
        className="clickable"
        onClick={() => navigation.pop()}
      >
        <Text>Supprimer mon compte</Text>
      </FloatingMenu.Header>
      <FloatingMenu.Body>
        <div>
          <Text size="medium" className="subtitleLightGrey">
            Entre ton mot de passe afin de supprimer définitivement ton compte
            Mangas.io
          </Text>

          <Input
            onChange={e => setPassword(e.target.value)}
            className="input"
            type="password"
            highlight={errors.length > 0 ? 'error' : null}
            label="Mot de passe"
            placeholder="********"
            value={password}
            required
          />
          {errors &&
            errors.map(error => (
              <Text className="error">{getErrorMessage(error)}</Text>
            ))}

          <Button
            label="Confirmer le mot de passe"
            variant="primary"
            fullWidth
            onClick={handleOnPress}
          />
        </div>
      </FloatingMenu.Body>
    </div>
  );
};

function RemoveAccountShield(props) {
  return (
    <GraphqlShield>
      {(errors = []) => <RemoveAccountSlide {...props} errors={errors} />}
    </GraphqlShield>
  );
}

export default RemoveAccountShield;
