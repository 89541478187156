import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '@mdi/react';
import {
  mdiInformationOutline,
  mdiCheck,
  mdiLightbulb,
  mdiAlert,
  mdiAlertOctagon,
  mdiPartyPopper,
  mdiLockOpen,
  mdiGiftOutline,
  mdiCrown,
} from '@mdi/js';

import './Disclaimer.scss';
import Text from '../Text/Text';

const icons = {
  info: mdiInformationOutline,
  success: mdiCheck,
  warning: mdiAlert,
  signup: mdiCrown,
  danger: mdiAlertOctagon,
  hint: mdiLightbulb,
  locked: mdiLockOpen,
  promotion: mdiGiftOutline,
  advertising: mdiPartyPopper,
};

const Disclaimer = ({
  className,
  type,
  title,
  text,
  icon,
  button,
  size,
  ...props
}) => (
  <div
    className={classnames(
      'mio-disclaimer',
      `mio-disclaimer-${type}`,
      `${size}`,
      className
    )}
  >
    <div className="container">
      <div className="childrenContent">
        <div className="firstLine">
          <div className="iconContent">
            {icon && <Icon id="disclaimerIcon" path={icon} />}
            {!icon && <Icon path={icons[type]} />}
          </div>
          <div className="textContainer">
            {title && (
              <Text size="small" bold>
                {title}
              </Text>
            )}
            {text && <Text size="small">{text}</Text>}
          </div>
        </div>
      </div>
      {button}
    </div>
  </div>
);

Disclaimer.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'info',
    'success',
    'promotion',
    'signup',
    'warning',
    'danger',
    'advertising',
    'hint',
    'locked',
  ]),
  size: PropTypes.oneOf(['small', 'large']),
  icon: PropTypes.element,
  title: PropTypes.string,
  text: PropTypes.string,
  button: PropTypes.element,
};

Disclaimer.defaultProps = {
  size: 'small',
  type: 'info',
};

export default Disclaimer;
