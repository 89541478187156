import { useHistory as useNavigationHistory } from 'react-router-dom';
import { useHistory } from 'hooks';
import { Button } from 'design-system';
import dayjs from 'dayjs';
import { repriseFormatter } from 'utils';

function ReadButtonConnected({ manga, ...buttonProps }) {
  const { data: history, loading } = useHistory(manga._id);
  const navigation = useNavigationHistory();
  if (loading) return null;

  const latestRead = history?.me.history[0];
  const firstChapter =
    manga.volumes.length > 0 &&
    manga.volumes[0].chapters.find(chapter => !chapter.isSeparator);

  const formattedHistory = latestRead ? repriseFormatter([latestRead]) : [];
  const isCompleted =
    formattedHistory[0]?.chapterIsFinished &&
    !formattedHistory[0]?.hasNextChapter;

  let label;
  let url;

  if (latestRead && !isCompleted) {
    label = 'Reprendre la lecture';
    url = `/lire/${manga.slug}/${latestRead.chapter.number}/${latestRead.page +
      1}`;
  } else if (isCompleted) {
    label = 'Recommencer la lecture';
    url = `/lire/${manga.slug}/${firstChapter.number}/1`;
  } else if (firstChapter && dayjs().isAfter(firstChapter.releaseDate)) {
    label = 'Commencer la lecture';
    url = `/lire/${manga.slug}/${firstChapter.number}/1`;
  } else {
    return null;
  }

  return (
    <Button
      variant="primary"
      label={label}
      {...buttonProps}
      onClick={() => navigation.push(url)}
    />
  );
}

export default ReadButtonConnected;
