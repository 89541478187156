import React from 'react';
import { ArticleCard } from 'design-system';

import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import './LinkedContent.scss';

function LinkedContent({
  wrapperElement: Wrapper = <></>,
  documents,
  ...props
}) {
  return (
    <Wrapper {...props}>
      {documents.map(article => {
        const external_link = article.data.lien_externe.url;
        return (
          <Link
            key={article.uid}
            target={external_link ? '_blank' : '_self'}
            rel={external_link ? 'noreferrer noopener' : ''}
            to={{
              pathname: external_link ? external_link : `/blog/${article.uid}`,
            }}
            className="contenuslies-container"
          >
            <ArticleCard
              picture={article.data.featured_image.url}
              title={article.data.title.map(title => title.text)}
              date={dayjs(article.last_publication_date).format('DD/MM/YYYY')}
            />
          </Link>
        );
      })}
    </Wrapper>
  );
}

export default LinkedContent;
