import React from 'react';
import { Button } from 'design-system';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import { useResponsive } from 'hooks';
import { BackLink, ResponsiveText } from 'UI';
import { CustomErrorBoundary } from 'utils';
import { ShareButton } from 'Components';

import './PublisherHeader.scss';

// REVIEW : Refacto avec des sous composants (PublisherHeader.Title, PublisherHeader.Subtitle) ?

const useResponsiveBanner = (banner, bannerMobile, loading) => {
  const { is } = useResponsive();
  const isMobile = !is('sm');
  if (loading) return {};

  let responsiveBanner = banner;
  if (isMobile && bannerMobile) responsiveBanner = bannerMobile;
  if (!responsiveBanner) return {};
  const posX = responsiveBanner.pos_x === null ? 50 : responsiveBanner.pos_x; // comparaison avec "null" pour autoriser les valeurs à 0
  const posY = responsiveBanner.pos_y === null ? 50 : responsiveBanner.pos_y;

  return {
    banner: responsiveBanner,
    style: {
      backgroundImage: `url(${responsiveBanner.url})`,
      backgroundPosition: `${posX}% ${posY}%`,
    },
  };
};

const PublisherHeader = ({ publisher }) => {
  const { is } = useResponsive();
  const isDesktop = is('lg');
  const isMobile = !is('sm');
  const { style: responsiveBannerStyle } = useResponsiveBanner(
    publisher.banner,
    publisher.bannerMobile,
    false
  );

  return (
    <div className="publisher-header">
      {!isDesktop && (
        <div className="box-buttons">
          <div className="box-buttons-left">
            <BackLink
              fallback="/"
              render={hasPreviousPage => (
                <Button
                  variant={isMobile ? 'text' : 'secondary'}
                  label={
                    !isMobile ? (hasPreviousPage ? 'Retour' : 'Accueil') : null
                  }
                  size="medium"
                  icon={<Icon path={mdiArrowLeft} />}
                />
              )}
            />
          </div>

          <div className="box-buttons-right">
            <ShareButton
              variant={isMobile ? 'text' : 'secondary'}
              label={isMobile ? null : 'Partager'}
            />
          </div>
        </div>
      )}
      <div
        className="header-main"
        style={{
          ...responsiveBannerStyle,
          paddingTop: is('sm') ? '30%' : '100%',
        }}
      ></div>
      <div className="bottom-header">
        <div className="columns">
          <div className="leftColumn">
            {publisher.subtitle && (
              <ResponsiveText size="small" className="subtitle">
                {publisher.subtitle}
              </ResponsiveText>
            )}
            <div className="title-main">
              <ResponsiveText as="h1" type="display" bold>
                {publisher.name}
              </ResponsiveText>
            </div>
          </div>
        </div>
        <div className="actions">
          <Button
            label="Voir les mangas disponibles"
            variant="primary"
            onClick={() =>
              document
                .getElementById('mangaListAnchor')
                .scrollIntoView({ behavior: 'smooth' })
            }
          />
          {isDesktop && <ShareButton size="large" label="Partager" />}
        </div>
      </div>
    </div>
  );
};

function PublisherHeaderError() {
  return null;
}
export default function PublisherHeaderWrapper(props) {
  return (
    <CustomErrorBoundary handleError={<PublisherHeaderError />}>
      <PublisherHeader {...props} />
    </CustomErrorBoundary>
  );
}
