import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './placeholderShape.scss';

const PlaceholderShape = ({ className, as: Tag, ...props }) => (
  <Tag className={classnames('mio-placeholder-shape', className)} {...props} />
);

PlaceholderShape.propTypes = {
  as: PropTypes.string,
};

PlaceholderShape.defaultProps = {
  as: 'div',
};

export default PlaceholderShape;
