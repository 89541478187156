import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './separator.scss';

/**
 * Separator component
 */
const Separator = ({ children, ...props }) => {
  return (
    <span className={classnames('mio-separator')} {...props}>
      {children}
    </span>
  );
};

Separator.propTypes = {
  children: PropTypes.element,
};

export default Separator;
