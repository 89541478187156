import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './discoverycard.scss';
import Tag from '../Tag/Tag';

const DiscoveryCard = ({
  className,
  size,
  picture,
  tags,
  loading,
  ...props
}) => {
  const style = {};
  if (!loading && picture) style.backgroundImage = `url(${picture})`;
  return (
    <div
      className={classnames('mio-discovery-card', { [size]: true }, className)}
      {...props}
    >
      <div className="image-container">
        <div className="image" style={style} />
      </div>
      {!loading && (
        <>
          <div className="tags">
            {tags.map((tag) => (
              <Tag key={tag} size={size === 'small' ? 'small' : 'medium'}>
                {tag}
              </Tag>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

DiscoveryCard.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
  picture: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
};

DiscoveryCard.defaultProps = {
  size: 'large',
  tags: [],
  loading: false,
};

export default DiscoveryCard;
