import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { defaultState } from 'hooks/useAppState';

import { useMutation, useAppState, profileSelector } from 'hooks';

const GQL_CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription {
    cancelSubscription
  }
`;

const GQL_UNCANCEL_SUBSCRIPTION = gql`
  mutation uncancelSubscription {
    uncancelSubscription
  }
`;

const GQL_UPDATE_ME = gql`
  mutation updateMe($input: ProfileInput!) {
    updateMe(input: $input) {
      _id
      isActive
      email
      avatar {
        url
      }
      notifications {
        push
        mail
      }
    }
  }
`;

const GQL_GET_ME = gql`
  query getMe {
    me {
      _id
      isActive
      email
      favoriteCategories {
        _id
        label
        level
      }
      notifications {
        push
        mail
      }
      avatar {
        url
      }
      referral {
        code
        completeCount
        pendingCount
        activationDate
      }
      info {
        _id
        status
        end_date
        subscriptionType
        plan
        price
        discount
        vendor
      }
      bundles {
        _id
        access
      }
      featureFlags
    }
  }
`;

export default function useProfile() {
  const [state, actions] = useAppState(profileSelector);
  const [cancelSubscriptionMutation] = useMutation(GQL_CANCEL_SUBSCRIPTION);
  const [uncancelSubscriptionMutation] = useMutation(GQL_UNCANCEL_SUBSCRIPTION);
  const [updateMeMutation] = useMutation(GQL_UPDATE_ME);
  const { loading, refetch } = useQuery(GQL_GET_ME, {
    fetchPolicy: 'network-only',
    skip: true,
  });

  const cancelSubscription = async () => {
    await cancelSubscriptionMutation();
    await refreshProfile();
  };

  const uncancelSubscription = async () => {
    await uncancelSubscriptionMutation();
    await refreshProfile();
  };

  const refreshProfile = async () => {
    const {
      data: { me },
    } = await refetch();
    if (me) actions.setProfileAction(me);
  };

  const clearProfile = () => {
    actions.setProfileAction({ ...defaultState.profile, loading: false });
  };

  const updateMe = async input => {
    await updateMeMutation(input);
    await refreshProfile();
  };

  return {
    profile: state,
    loading: loading || !!state.loading,
    loaded: !!state._id,
    cancelSubscription,
    uncancelSubscription,
    updateMe,
    refreshProfile,
    clearProfile,
  };
}
