import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Bloc, Button, Text, Tag } from 'design-system';

import { BlurImg, ResponsiveText } from 'UI';
import { PageTurn } from 'Components';
import { useRatio, useResponsive, usePage } from 'hooks';
import {
  CustomErrorBoundary,
  ViewportOnly,
  getResizedUrl,
  withPixelDensity,
} from 'utils';

import './Preview.scss';
import Icon from '@mdi/react';
import { mdiArrowLeftThick, mdiArrowRightThick, mdiPlay } from '@mdi/js';

const MentionsComponent = ({ height, width, copyright }) => {
  return (
    <div className="page read-more mentions" style={{ height, width }}>
      <ResponsiveText className="chapterTitle" type="display" size="small" bold>
        Mentions légales
      </ResponsiveText>
      <ResponsiveText type="text" size="small" id="mentions-text">
        {copyright ? copyright : 'à venir...'}
      </ResponsiveText>
    </div>
  );
};

const ReadMangaComponent = ({ height, width, manga, title }) => {
  return (
    <div className="page read-more" style={{ height, width }}>
      <img
        id="readmoreThumbnail"
        src={getResizedUrl(manga.thumbnail && manga.thumbnail.url, {
          height: withPixelDensity(200),
        })}
        alt={manga.title}
      />
      <ResponsiveText className="chapterTitle" type="desktop" size="medium">
        {title}
      </ResponsiveText>
      <ResponsiveText type="display" size="small" bold>
        {manga.title}
      </ResponsiveText>
      <div className="tagList">
        {manga.categories &&
          manga.categories.map(tag => (
            <Tag id="tag" key={tag.label}>
              {tag.label}
            </Tag>
          ))}
      </div>
      <Button
        variant="primary"
        icon={<Icon path={mdiPlay} />}
        label="Continuer la lecture"
      />
    </div>
  );
};

const ReadMoreComponent = ({ height, width, manga, chapter }) => {
  return manga.direction === 'ltr' ? (
    <>
      <MentionsComponent
        height={height}
        width={width}
        copyright={chapter.copyright}
      />
      <ReadMangaComponent
        height={height}
        width={width}
        manga={manga}
        title={chapter.title}
      />
    </>
  ) : (
    <>
      <ReadMangaComponent
        height={height}
        width={width}
        manga={manga}
        title={chapter.title}
      />
      <MentionsComponent
        height={height}
        width={width}
        copyright={chapter.copyright}
      />
    </>
  );
};

const Page = props => {
  const { chapter, manga, page, height, width, onClick } = props;
  const [load, loadedPage] = usePage(page._id, { quality: 'thumbnail' });

  return (
    <>
      {manga.direction === 'rtl' && onClick && (
        <ReadMoreComponent
          height={height}
          width={width}
          manga={manga}
          chapter={chapter}
        />
      )}
      <div className="page" style={{ height, width }}>
        <BlurImg
          image={{
            ...page.image,
            ...loadedPage?.image,
          }}
          reload={load}
        />
      </div>
      {manga.direction === 'ltr' && onClick && (
        <ReadMoreComponent
          height={height}
          width={width}
          manga={manga}
          chapter={chapter}
        />
      )}
    </>
  );
};

const Preview = props => {
  const {
    skip,
    limit,
    pages,
    manga,
    chapter,
    backgroundImage,
    isInView = true,
  } = props;
  const history = useHistory();
  const [currentPage, setPage] = useState(
    manga?.direction === 'ltr' ? pages.length - 3 : pages.length + 1
  );
  const [readerRef, height, containerSizes] = useRatio(1.5);
  const { is } = useResponsive();

  if (!manga) return null;

  const sortPages =
    manga.direction === 'ltr' ? [...pages] : [...pages].reverse();
  const isDesktop = is('md');
  const pageStep = isDesktop ? 2 : 1;
  const translateX = isDesktop
    ? containerSizes.width / 2 + (-containerSizes.width / 2) * currentPage
    : -containerSizes.width * currentPage;
  const pageHeight = isDesktop ? height / 2 : height;
  const pageWidth = isDesktop ? '50%' : '100%';

  return (
    <Bloc
      invertTitles
      subtitle="APERÇU"
      title={manga.title}
      className="Preview"
      title_right={
        <div
          className="titleRight"
          style={{
            flexDirection: manga.direction === 'rtl' ? 'row' : 'row-reverse',
          }}
        >
          <Icon
            path={
              manga.direction === 'rtl' ? mdiArrowLeftThick : mdiArrowRightThick
            }
          />
          <Text className="title" type="desktop" size="small" bold>
            Sens de lecture
          </Text>
        </div>
      }
    >
      <div
        className="preview-wrapper"
        style={{ backgroundImage: `url(${backgroundImage.url})` }}
      >
        <div
          className="reader"
          style={{
            height: pageHeight,
          }}
        >
          <div
            ref={readerRef}
            className="pages"
            style={{
              transform: `translateX(${translateX}px)`,
            }}
          >
            {isInView &&
              sortPages.map((page, index) => (
                <Page
                  key={page._id}
                  page={page}
                  height={pageHeight}
                  width={pageWidth}
                  manga={manga}
                  chapter={chapter}
                  onClick={
                    manga.direction === 'ltr'
                      ? index === pages.length - 1
                      : index === 0
                  }
                />
              ))}
          </div>
          {manga.direction === 'rtl' && (
            <PageTurn
              manga={manga}
              isFirstPage={currentPage === pages.length + 1}
              isLastPage={currentPage === 1}
              onClickLeft={() => {
                if (currentPage > -1 && currentPage - pageStep > -1)
                  setPage(currentPage - pageStep);
                else if (currentPage === 1 || currentPage < 1) {
                  history.push(
                    `/lire/${manga.slug}/${chapter.number}/${skip + limit}`
                  );
                }
              }}
              onClickRight={() =>
                currentPage < pages.length && setPage(currentPage + pageStep)
              }
            />
          )}
          {manga.direction === 'ltr' && (
            <PageTurn
              manga={manga}
              isFirstPage={currentPage < 2}
              isLastPage={currentPage === pages.length + 1}
              onClickRight={() => {
                if (currentPage < pages.length + 1)
                  setPage(currentPage + pageStep);
                else if (currentPage === pages.length + 1) {
                  history.push(
                    `/lire/${manga.slug}/${chapter.number}/${skip + limit}`
                  );
                }
              }}
              onClickLeft={() =>
                currentPage > 1 && setPage(currentPage - pageStep)
              }
            />
          )}
        </div>
      </div>
    </Bloc>
  );
};

function ShieldedPreview(props) {
  return (
    <CustomErrorBoundary>
      <ViewportOnly>
        <Preview {...props} />
      </ViewportOnly>
    </CustomErrorBoundary>
  );
}

export default ShieldedPreview;
