import React from 'react';
import { useSinglePrismicDocument } from '@prismicio/react';
import { asText } from '@prismicio/helpers';
import { useHistory } from 'react-router-dom';

import { ArticleCard, PlaceholderShape } from 'design-system';
import { CustomErrorBoundary } from 'utils';
import { ResponsiveText } from 'UI';

import './BlogHighlights.scss';

function Highlight({ loading, post }) {
  const history = useHistory();
  const navigate = history.push;

  if (loading)
    return (
      <div className="main-highlight">
        <PlaceholderShape
          className="featured_image"
          style={{ borderRadius: 8 }}
        />
        <div className="texts">
          <PlaceholderShape
            style={{ width: '40%', height: 30, marginTop: 20 }}
          />
          <PlaceholderShape
            style={{ width: '80%', height: 90, marginTop: 20 }}
          />
        </div>
      </div>
    );

  const { data } = post;
  const { featured_image, title, meta_description, lien_externe } = data;

  return (
    <div
      className="main-highlight"
      onClick={() =>
        lien_externe?.url
          ? window.open(lien_externe.url, '_blank')
          : navigate(`/blog/${post.uid}`)
      }
    >
      <div className="featured_image">
        <div
          className="featured_image_bg"
          style={{
            backgroundImage: `url(${featured_image.url})`,
          }}
        />
      </div>
      <div className="texts">
        <ResponsiveText type="display" size="small" bold>
          {asText(title)}
        </ResponsiveText>
        <ResponsiveText type="text" size="large" className="faded">
          {asText(meta_description)}
        </ResponsiveText>
      </div>
    </div>
  );
}

function BlogHighlights() {
  const history = useHistory();
  const navigate = history.push;
  const [document, { state }] = useSinglePrismicDocument('blog_meta', {
    fetchLinks: [
      'blog_post.featured_image',
      'blog_post.title',
      'blog_post.meta_description',
      'author.name',
      'author.avatar',
      'author.role',
      // 'blog_post.author',
      // 'document.first_publication_date',
    ],
  });
  const loading = state !== 'loaded';

  const meta = !loading
    ? {
        featured: document.data.featured.map(({ post }) => post),
        highlight: document.data.highlight,
      }
    : {
        highlight: null,
        featured: [],
      };

  return (
    <div className="BlogHighlights">
      <div className="main-container">
        <ResponsiveText
          className="withHorizontalBar"
          type="display"
          size="small"
          bold
        >
          À la une !
        </ResponsiveText>
        {meta.highlight && (
          <Highlight loading={!meta.highlight} post={meta.highlight} />
        )}
      </div>

      <div className="aside-container is-hidden-mobile">
        <ResponsiveText type="display" size="small" bold>
          À ne pas manquer
        </ResponsiveText>
        {meta.featured.map(({ data, uid, lien_externe }) => (
          <ArticleCard
            onClick={() =>
              lien_externe?.url
                ? window.open(lien_externe.url, '_blank')
                : navigate(`/blog/${uid}`)
            }
            key={uid}
            picture={data.featured_image?.url}
            title={asText(data.title)}
          />
        ))}
        {!meta.featured.length &&
          [1, 2, 3].map(key => <ArticleCard key={key} />)}
      </div>
    </div>
  );
}

export default function BlogHighlightsWrapper(props) {
  return (
    <CustomErrorBoundary handleError={() => null}>
      <BlogHighlights {...props} />
    </CustomErrorBoundary>
  );
}
