import React, { useState, useEffect } from 'react';
import { Switch, useHistory, MemoryRouter, Link } from 'react-router-dom';
// import { TransitionGroup, CSSTransition } from 'react-transition-group';

import { mdiAlertCircleOutline } from '@mdi/js';
import { Disclaimer, Text, Tag } from 'design-system';
import classNames from 'classnames';

import { Form, FormFooter, Responsive } from 'UI';
import { PricingLine } from 'Components';
import { PublicRoute, track } from 'utils';
import { useBrowserHistory, useOffers } from 'hooks';

import StripeLoader from './StripeLoader';
import CodeSelectRouter from './CodeSelect.routes';
import SubscriptionSuccess from './SubscriptionSuccess';

import './Subscription.scss';

const PriceCard = ({ offer, action }) => {
  return (
    <button
      type="submit"
      onClick={action}
      className={classNames('priceCard', offer.variant)}
    >
      <div className="leftComponent">
        <Text>{offer.center}</Text>
        {offer.tag && <Tag>{offer.tag}</Tag>}
      </div>

      <div className="rightComponent">
        <div>
          <span className="price">
            {offer.right.value} <span>{offer.right.side}</span>
          </span>
        </div>
        {offer.right.underline && (
          <div className="promo">{offer.right.underline}</div>
        )}
      </div>
    </button>
  );
};

function Subscription(props) {
  const history = useHistory();
  const { error, plan, setPlan } = props;
  const offers = useOffers('radio');
  const [offersLoaded, setOffersLoaded] = useState(false);
  const browserHistory = useBrowserHistory();

  useEffect(() => {
    if (!offersLoaded && offers) setOffersLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offers]);

  useEffect(() => {
    if (offersLoaded && offers)
      track('Product List Viewed', {
        products: offers.map(product => ({
          product_id: product.plan,
        })),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offersLoaded]);

  useEffect(() => {
    const offer = offers.find(offer => offer.planId === plan);
    if (offer)
      track('Product Viewed', {
        product_id: offer.plan,
        price: offer.price,
        currency: 'EUR',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);

  function handleSubmit(e) {
    e.preventDefault();

    const offer = offers.find(offer => offer.planId === plan);
    if (offer)
      track('Cart Viewed', {
        products: [
          {
            product_id: offer.plan,
            price: offer.price,
            currency: 'EUR',
            quantity: 1,
          },
        ],
      });

    if (plan === 'free') browserHistory.push('/success/free');
    else {
      history.push('/stripe-loader');
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Form>
        {error && (
          <Disclaimer type="danger" icon={mdiAlertCircleOutline}>
            {error}
          </Disclaimer>
        )}
        <Responsive>
          <>
            <Text type="display" size="small" bold>
              Lis des milliers de mangas
            </Text>
            <Text>au prix d’un manga par mois !</Text>
            <Text className="spacer">🎁 7 jours gratuits</Text>
            <Text>🔓 Intégralité du catalogue </Text>
            <Text>📚 Traduction officielle </Text>
            <Text>🤩 Lecture HD sans pub </Text>
            <Text>✅ Sans engagement</Text>
          </>
        </Responsive>

        <Disclaimer
          className="nudge"
          type="hint"
          text="7 jours d'essai gratuits pour tous nos abonnements, sans engagement !"
        />

        {offers.map((offer, key) => {
          return (
            <PricingLine key={key}>
              <PriceCard offer={offer} action={() => setPlan(offer.planId)} />
            </PricingLine>
          );
        })}

        <Responsive>
          <Text size="small" className="spacer">
            Paiement sécurisé en ligne effectué par <strong>Stripe</strong>
          </Text>
        </Responsive>
      </Form>

      <FormFooter>
        <Text className="text-grey" size="small" mobile>
          Tu as une carte cadeau ?{' '}
          <Link className="text-link" onClick={() => history.push('/code')}>
            Active-la !
          </Link>
        </Text>
      </FormFooter>
    </form>
  );
}

// function SubscriptionRoutesWithTransition() {
//   const location = useLocation();
//   const [error, setError] = useState(null);
//   return (
//     <TransitionGroup>
//       <CSSTransition timeout={300} classNames="SlideIn" key={location.key}>
//         <Switch location={location}>
//           <PublicRoute
//             exact
//             path="/"
//             component={<Subscription error={error} setError={setError} />}
//           />
//           <PublicRoute
//             path="/stripe-loader"
//             component={<StripeLoader error={error} />}
//           />
//           <PublicRoute path="/code" component={<CodeSelectRouter />} />
//         </Switch>
//       </CSSTransition>
//     </TransitionGroup>
//   );
// }

export default function SubscriptionRouter(props) {
  const { onSubmit, hasDiscoveryPlan = true, initialRouterConfig } = props;
  const [error, setError] = useState(null);
  const [plan, setPlan] = useState('free');

  return (
    <div id="mio-subscription">
      <MemoryRouter initialEntries={initialRouterConfig}>
        <Switch>
          <PublicRoute
            exact
            path="/"
            component={
              <Subscription
                error={error}
                setError={setError}
                plan={plan}
                setPlan={setPlan}
                hasDiscoveryPlan={hasDiscoveryPlan}
                onFinish={onSubmit}
              />
            }
          />
          <PublicRoute
            path="/stripe-loader"
            component={<StripeLoader error={error} plan={plan} />}
          />
          <PublicRoute path="/code" component={<CodeSelectRouter />} />
          <PublicRoute
            exact
            path="/success/:type"
            component={
              <SubscriptionSuccess
                currentLocation={props.currentLocation}
                onClick={onSubmit}
              />
            }
          />
        </Switch>
      </MemoryRouter>
    </div>
  );
}
