import React, { useState } from 'react';
import { mdiAlertCircleOutline } from '@mdi/js';
import classnames from 'classnames';
import {
  useRouteMatch,
  useHistory,
  useParams,
  Redirect,
} from 'react-router-dom';
import {
  Text,
  Input,
  Button,
  Disclaimer,
  Checkbox,
} from 'design-system';

import { OnboardingBackground, Form } from 'UI';
import { PublicRoute, trackHandler } from 'utils';
import { useAuth, useAppState, newsletterSubscriptionSelector } from 'hooks';

import './ActivationPassword.scss';

function mapStoreToState(state) {
  return {
    newsletterSubscription: newsletterSubscriptionSelector(state),
  };
}

function ActivationPasswordForm() {
  const { token } = useParams();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const { setNewPassword } = useAuth();
  const [error, setError] = useState();
  const [cgu, setCgu] = useState(false);
  const [{ newsletterSubscription }, actions] = useAppState(mapStoreToState);

  async function handleSubmit(e) {
    e.preventDefault();
    if (!cgu) {
      setError("Tu dois accepter les Conditions Générales d'Utilisation");
      return;
    }
    if (token && password) {
      try {
        await setNewPassword({ token, password });
        history.push({
          pathname: '/login',
          state: {
            disclaimer: 'PASSWORD_UPDATED',
          },
        });
      } catch (e) {
        if (e.message.includes('password:')) setError('INVALID_PASSWORD');
        else setError(e.message);
      }
    }
  }

  function getErrorMessage(error) {
    console.error({ error });
    if (error === 'INVALID_PASSWORD')
      return 'Ton mot de passe doit contenir au minimum 8 caractères';
    if (error === 'INVALID_TOKEN')
      return 'Ton lien de confirmation est invalide ou a expiré';
    return error;
  }

  return (
    <div className="mio-activation-password">
      <form onSubmit={handleSubmit}>
        <Form>
          <Text size="large" className="header" bold>
            Bienvenue sur Mangas.io !
          </Text>
          <Text size="medium" className="header">
            Afin de terminer la création de ton compte, merci de définir un mot
            de passe de connexion.
          </Text>

          {error && (
            <Disclaimer
              type="danger"
              icon={mdiAlertCircleOutline}
              text={getErrorMessage(error)}
            />
          )}

          <Input
            onChange={e => setPassword(e.target.value)}
            className={classnames('input', {
              'is-error': error && error.includes('INVALID_PASSWORD'),
            })}
            type="password"
            label="Mot de passe"
            placeholder="••••••••••••••••"
            value={password}
            required
          />

          <Checkbox
            id="cgu"
            checked={cgu}
            onChange={({ checked }) => setCgu(checked)}
          >
            <Text>
              En cochant cette case, je certifie avoir lu et accepter sans
              réserve les{' '}
              <a
                href="https://www.notion.so/mangas/Conditions-G-n-rales-d-Utilisation-de-Mangas-io-bf445dbbcf514f218cbf31ac748c04e8"
                onClick={e => trackHandler(e, 'Terms of Service Clicked')}
                target="_blank"
                rel="noreferrer noopener"
              >
                Conditions Générales d'Utilisation (CGU)
              </a>{' '}
              de Mangas.io
            </Text>
          </Checkbox>
          <Checkbox
            id="newsletter"
            checked={!!newsletterSubscription}
            onChange={({ checked }) =>
              actions.setNewsletterSubscriptionAction(checked)
            }
          >
            <Text>
              Newsletter sans pub : nouvelles lectures et suggestions, nouvelles
              fonctionnalités, message de la team...
            </Text>
          </Checkbox>

          <Button
            className="btnNewPw"
            label="Confirmer le mot de passe"
            variant="primary"
            fullWidth
            type="submit"
          />
        </Form>
      </form>
    </div>
  );
}

export default function ActivationPassword() {
  const match = useRouteMatch();
  return (
    <OnboardingBackground>
      <PublicRoute exact path={match.path} component={<Redirect to="/" />} />
      <PublicRoute
        path={`${match.path}/:token`}
        component={<ActivationPasswordForm />}
      />
    </OnboardingBackground>
  );
}
