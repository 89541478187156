import React from 'react';
import { Button, Text } from 'design-system';
import { useQuery, useResponsive } from 'hooks';
import gql from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { Footer, MangaGrid, NotFound, SEO } from 'Components';
import { BackLink, Page, Responsive } from 'UI';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import './AuthorPage.scss';

const AUTHOR_FRAGMENT = `
_id
name
slug
description
avatar {
  url
}
mangas {
  _id
  slug
  chapterCount
  title
  thumbnail {
    url
  }
  categories {
    label
    level
  }
}  
`;

const GQL_GET_AUTHOR_BY_SLUG = gql`
  query GetAuthor($slug: String) {
    author(slug: $slug) {
      ${AUTHOR_FRAGMENT}
    }
  }
`;

const GQL_GET_AUTHOR_BY_ID = gql`
  query GetAuthor($id: ID) {
    author(id: $id) {
      ${AUTHOR_FRAGMENT}
    }
  }
`;

export default function AuthorPage(props) {
  const { is } = useResponsive();
  const isMedium = is('md');
  const { slug } = useParams();

  // RETROCOMPATIBILITY : work both with id and slug
  // CAUTION : if the slug matches the regex, it will be treated like an id, which may cause false positives
  const slugIsAnId = /^[0-9a-fA-F]{24}$/.test(slug);
  const { data, loading } = useQuery(
    slugIsAnId ? GQL_GET_AUTHOR_BY_ID : GQL_GET_AUTHOR_BY_SLUG,
    {
      variables: slugIsAnId ? { id: slug } : { slug },
    }
  );

  const { author } = data || {};

  if (!author && !loading) return <NotFound />;

  if (loading) return <div id="AuthorPage" />;
  //TO DO ^^^^
  return (
    <Page id="AuthorPage">
      <SEO
        title={`Retrouve les titres de ${author.name} à l'abonnement sur Mangas.io`}
        description={`Toutes les informations sur ${author.name}${
          author.mangas.length > 0
            ? `, l'auteur.ice de ${author.mangas[0].title}, `
            : ''
        } sont sur Mangas.io ! Retrouve également ses titres à l'abonnement sur Mangas.io`}
      />
      <div className="author-header">
        <BackLink
          fallback="/"
          render={hasPreviousPage => (
            <Button
              variant={isMedium ? 'secondary' : 'text'}
              label={isMedium ? (hasPreviousPage ? 'Retour' : 'Accueil') : null}
              size="large"
              icon={<Icon id="back-icon" path={mdiArrowLeft} />}
            />
          )}
        />
      </div>
      <div id="AuthorPage-container">
        <div className="titleContainer">
          <div className="author-title">
            <img
              className="img-author"
              src={
                author.avatar
                  ? author.avatar.url
                  : 'https://cdn.mangas.io/images/no-picture-author.png'
              }
              alt={author.name}
              width="64"
              height="64"
            />
            :
            <Text
              as="h1"
              type="display"
              size={isMedium ? 'medium' : 'small'}
              bold
            >
              {author.name}
            </Text>
          </div>
          <Text className="authorDescription" type="text" size="medium">
            {author.description}
          </Text>
        </div>

        <div className="author-mangas-container">
          {author.mangas.length > 0 ? (
            <div className="title-availability">
              <Responsive>
                <Text as="h2" type="display" bold size="small">
                  Titres inclus dans l'abonnement Mangas.io
                </Text>
              </Responsive>
            </div>
          ) : null}
          <MangaGrid mangas={author.mangas} loading={loading} />
        </div>
      </div>
      <Footer />
    </Page>
  );
}
