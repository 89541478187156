import React from 'react';
import gql from 'graphql-tag';
import { Text } from 'design-system';

import { MangaLine } from 'Components';
import { useQuery } from 'hooks';
import { CustomErrorBoundary } from 'utils';

import './TrendingLine.scss';

const GET_COLLECTIONS_LINES = gql`
  query getCollectionsLines($filter: FilterCollectionInput) {
    featuredCollections(filter: $filter) {
      _id
      title
      position
      mangas {
        _id
        slug
        title
        thumbnail {
          url
        }
        categories {
          label
          level
        }
      }
    }
  }
`;

function TrendingLine() {
  const { data, loading } = useQuery(GET_COLLECTIONS_LINES, {
    variables: { filter: { collectionType: 'TRENDING' } },
  });

  if (loading) {
    return <MangaLine loading={true} />;
  }

  const { mangas, title, _id: key } = data.featuredCollections[0];
  if (mangas.length < 1) return null;

  return (
    <MangaLine
      tileSize="medium"
      tileDecorator={({ index }) => (
        <div style={{ marginRight: '-10px', opacity: '0.6' }}>
          <div className="mangaline-tagContent">
            <Text type="display" size="small" bold>
              {index + 1}
            </Text>
          </div>
        </div>
      )}
      key={key}
      mangas={mangas}
      title={title}
    />
  );
}

export default function ShieldedTrendingLine() {
  return (
    <CustomErrorBoundary handleError={() => null}>
      <TrendingLine />
    </CustomErrorBoundary>
  );
}
